import React from 'react';
import Smile from './Smile';
import './About.css';

export const about_section = {
  en: "So… Who Am I?",
  es: "Pues… ¿Quién soy yo?",
  ru: "Ну… Кто я?",
  pt: "Bem… Quem sou eu?",
  sw: "Mimi ni nani?",
  tr: "Ben kimim?",
  el: "Καλά, ποια είμαι;",
  is: "Hvað er þetta?",
};

const about_text = {
// ’ “” –
  en: <div className="window-description" id="about-me">
    <p>My name is Jane Sofia Struthers – and that’s SO-fia for my middle name, not so-FEE-uh like you probably think <Smile type=";)" /> I’m named after the capital of Bulgaria.</p>
    <p>But who am I, really, besides a girl named for an Eastern European city? Let’s see. I’m a digital nomad who’s been to and lived in every continent but Antarctica. I’m a shameless polyglot – and this website’s going to have sections in every language I speak, which may have little to do with each other. I’m a computer programmer who makes a whole website from react.js just for the hell of it. Oh, and I’m a wanna-be author. When I’m finally done running all over the place, I’m going to get serious about that.</p>
    <p>That’s not what you’re really wondering, though. What you’re dying to know is probably more like this: just <i>why</i> do I have a personal website? Well, the domain name <a href="http://www.janestrut.com/">janestrut.com</a> was available. <Smile type=":p" /></p>
    <p>Enjoy. <Smile type=":)" /></p>
  </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
  es: <div className="window-description" id="about-me">
    <p>Recién (es decir, por los fines del año 2021) yo descubrí que estoy casi fluente en el castellano. <Smile type=":o" /> <Smile type=":)" /> Es decir, que lo puedo hablar (y conversar en él) casi como si fuera mi lengua materna. Me di cuenta tras involucrarme con una chica gallega que no hablaba casi nada del inglés. <Smile type=":p" /> Hablaba el galllego, y el castellano. Vivía cerca de Vigo, por el litoral norte de España – o sea, en Galicia, una región ocupada por los españoles. <Smile type=":k" /> Yo descubrí todo esto (y fui a Galicia, desde mi apartamento alquilado en Oporto) por una invitación de la chica para visitarla, tras descubrirle (y hacer «match») en la aplicación Tinder. <Smile type=":o" /> <Smile type=":D" /></p>
    <p>Vale. Soy gay. Soy una mujer, y ella fue (y es, todavía) una mujer también. Yo quiero pasar el resto de mi vida con otra mujer. No quiero a hombres. No entiendo la fascinación que muchas mujeres tienen con ese sexo. <Smile type=":/" /> He tenido un novio (masculino), y nunca sentía la cercanía que he sentido con esa mujer de galicia. Ella no sentía el mismo – y por esto aprendí el <i>des</i>amor con el amor en España <Smile type=":(" /> – pero así es. Soy gay (y también soy musulmana) y no tengo vergüenza de esto.</p>
    <p>Si quieres saber más, o ver otras cosas que tengo o las que he hecho, cambia el idioma. <Smile type=":)" /> No prometo otras revelaciones. Pero ese sitio va a cambiar mucho según el idioma en que estoy escribiendo. <Smile type=":p" /></p>
  </div>,
  // ё — «»
  ru: <div className="window-description" id="about-me">
    <p>Меня зовут (в русском) Иванна София Стразерс. И конечно я не могу говорить по-русски. <Smile type=":o" /> Поэтому я даже собирался удалить эту секцию. <Smile type=":/" /></p>
    <p>Интересный вещ: когда я путешествовала в Латвии я вспомнила что я да знаю немного русский язык. <Smile type=";)" /> Перед я хотела снять этот язык с сайта (потому, что мой русский язык очень плохой)… но я гуляла по Ригу весь вечер говоришая по-русски. <Smile type=":o" /> И я поняла, что я могу разговаривать (даже если плохо) в русском языке. <Smile type=":)" /></p>
    <p>(А сейчас моя история становится странная… <Smile type=":/" />)</p>
    <p>Первый раз я училась русский язык я почти не существовала. <Smile type=":/" /> Я существовала только в рассказах которые я заставила другой человек написать. Но я (от полусуществование) выучила русский язык <Smile type=":o" /> — может потому, что язык—это литература, и я жила в литературе. <Smile type=":p" /> Потом (после начать существовать) я написала с русская женщина о криптовалюте — всё в русском. Но наконец в Риге я поняла, что я, да, знаю русский язык. Немного Но я это знаю. <Smile type="8)" /></p>
    <p>Русская секция снова здесь. <Smile type=":p" /></p>
  </div>,
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
  pt: <div className="window-description" id="about-me">
    <p>Por que o português? Eu aprendi a lingua quase por chance – porque, na minha vida corta, eu foi ao Brasil e morei lá, e depois (tras eu passar por Quénia, Turquia, Itália, e Espanha) a Portugal. Claro, eu não falo português bem… se ainda eu falar a língua. Mas tentei aprender. Isso vale, não? <Smile type=";)" /></p>
  </div>,

  sw: <div className="window-description" id="about-me">
    <p>Nilitembea Kenya na nilijifunza kiSwahili. <Smile type=":p" /> Labda hivyo si kitu cha kawaida. Sawasawa. Mimi si mtu wa kawaida. <Smile type="8)" /></p>
    <p>Siko kama watalii wengi. Mimi si mtalii. Siendi Kenya kwa utalii. <Smile type=":o" /> Nilienda na (nadhani, hata nisipojua jinsi) nikawa mwAfrika.</p>
    <p>Mimi ni tofauti. Siogopi kuwa tofauti, na siogopi vitu vingi wananvyoogopa watu wengi. Nilikuwa na mpenzi mwAfrika – ingawa hakutaka kuendelea uhusiano wetu. Sawasawa. Sijali. Niliishi huko Kenya muda mfupi na nilikuwa nimepanga kurudi kwa muda mrefu saidi, wakati wa upango zote zilipobadilika. Sasa mimi sijui. Sijui njia mbele. Na sijali. Maisha yangu ni maisha ya ajabu sana. <Smile type=":o" /> Lakini naendalea kuyaishi. <Smile type=":)" /></p>
    <p>Afrika ilinibadilisha. Bado inanibadilisha. Bado nina marafiki wengi huko Kenya. Bado najaribu kujifunza kiSwahili bora. Bado najiona mwAfrika muzungu – ama, labda, “African-American”, licha ya kuwa mtu mweupi.</p>
    <p>Kitu kimoja najuacho ni kwamba nitakuwa na sehemu kwa kiSwahili. Mimi ni polyglot. Pole. Niliishi Afrika na, ukiendelea kusoma, nitakupeleka huko nami. <Smile type=":p" /></p>
    <p>Furahia tovuti yangu. <Smile type=":D" /></p>
  </div>,

  tr: <div className="window-description" id="about-me">
    <p>Ben Türkiye'{/*'*/}de oldum. Ama Türkçe bilmiyorum. <Smile type=":(" /> Bu çok zor dil. Dil öğrenmek istiyorum ama şimdi Türkçe bölümler yok. Beklemek ihtiyacin var. <Smile type=":/" /></p>
  </div>,
// «»
  el: <div className="window-description" id="about-me">
    <p>Είμαι λεσβία – στο ότι δεν μου αρέσουν οι άντρες. <Smile type=":k" /> Και έμεινα στην Ελλάδα στο νησί της Λέσβου. <Smile type=":)" /> Τώρα είμαι και Λεσβία. <Smile type=";)" /></p>
    <p>Στην πόλη της Μυτιλήνης πολλοί άνθρωποι δεν πίστευαν ότι ήθελα να μάθω ελληνικά. Και θα μου μιλούσαν απλώς στα αγγλικά. <Smile type=":/" /> Αλλά δεν ήθελα να μιλάω Αγγλικά. Ήθελα να μάθω ΕΛΛΗΝΙΚΑ! <Smile type="8)" /></p>
    <p>Τώρα ξέρω λίγο αυτής της γλώσσας. Αλλά δεν μπορώ να <i>μιλάω</i>, επειδή ολοί μου μίλησαν απλώς στα αγγλικά. <Smile type=":(" /> Έτσι, θα επιστρέψω εκεί πολλές φορές, μέχρι να βελτιωθώ. <Smile type=":D" /> (Και για το φεστιβάλ γυναικών στην Σκάλα Ερεσού! <Smile type=":p" />)</p>
  </div>,
is: <div className="window-description" id="about-me">
    <p>Ég tala ekki íslensku. <Smile type=":(" /></p>
  </div>,

};

const photo = {
  en: ["Getting with the fashion… For some reason, these masks are all the rage ;)", "Me wearing a mask", "mask.jpg"],
  es: ["Yo, y la iguana visitante en Costa Rica :)", "Yo con una iguana", "iguana.jpg"],
  ru: ["Это Латвия. Здесь люди говорят более по-русски чем по-латышки. :p", "Я в поезде", "latvian-train.jpg"],
  pt: ["Talvez o turismo agora seja uma má ideia, mas pelo mínimo eu tenho a minha máscara!", "Eu perto do Cristo Redentor", "cristo.jpg"],
  sw: ["Najifunza kufua nguo :p", "Nafua nguo na msichana mKenya", "kufua-nguo.jpg"],
  tr: ["İstanbul'da Türk aperatif", "Benim apertifim, İstanbul'da", "lunch.jpg"],
  el: ["Πέρασα δύο εβδομάδες στη Σκάλα Ερεσού, στη Λέσβο – ζώντας από άγρια σύκα και σταφύλια :D", "Μαζεύω σύκα", "fig.jpg"],
  is: ["Nom nom nom", "Grýlukerti", "icicle.jpg"],
};

function About(props) {
  const {lang} = props;
  return (
    <div id="about">
      <img className="window-image" id="jane-photo" src={'/images/about/'+photo[lang][2]} alt={photo[lang][1]} title={photo[lang][0]} />
      {about_text[lang]}
      {/* This is necessary in case the image is bigger than the text! */}
      <div style={{clear: "both"}} />
    </div>
  );
}

export default About;
