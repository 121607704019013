import React from 'react';
import Smile from '../Smile';
import {disp_num} from './Ingredient';
import {ingredients, measures} from './ingredients';

/*function saltOnionGarlic(scaleSalt=1, scaleOnion=1, scaleGarlic=1) {
  const salt = (scaleSalt === 3 || scaleSalt >= 6)
    ? {...ingredients.salt,
      size: scaleSalt/3,
      measure: "tbsp",
    } : {...ingredients.salt,
      size: scaleSalt,
      measure: "tsp",
    };
  return [
    salt,
    {...ingredients.onion,
      form: "diced",
      size: scaleOnion,
      modifier: "large",
      guide: (scale) => "Or " + disp_num(scale*scaleOnion*2) + " small",
    },
    {...ingredients.garlic,
      form: "mashed",
      size: scaleGarlic,
      measure: "clove",
      guide: (scale) => "You can also use " + disp_num(scale) + " tsp" + addS(scale) + " pre-mashed garlic.",
    },
  ];
}*/

function addS(num, singular="", plural="s") {
  return (num !== 0 && num <= 1 && disp_num(num).length === 1)
    ? singular
    : plural;
}

function russian_plural(scale, sing="", plu="а", mult="ов") {
  if (scale % 10 === 1/* && (scale % 1).closeTo(0)*/) {return sing;}
  else if (scale % 10 < 5) {return plu;}
  else return mult;
}

function showRange(low, high) {
  if (low === high) {return disp_num(high);}
  return disp_num(low) + "-" + disp_num(high);
}

export const recipes = [
  {
    name: {
      en: "Lemony Hummus",
      es: "Hummus de limón",
      ru: "Лимонный хумус",
      pt: "Homus de limão",
    },
    image: "lemony-hummus.jpg",

    description: {
      en: <div className="recipe-description">
        <p>This is what happens when you’re making hummus and have a ton of lemon juice you’re trying to use up <Smile type=";)" /> Good news: it actually tastes pretty yummy! <Smile type="8)" /></p>
      </div>,
      es: <div className="recipe-description">
        <p>Al mismo tiempo en que estaba horneando molletes de limón, apreté los limones y terminé con bastante jugo… <Smile type=":o" /> ¿Cómo usarlo? Mi solución: ¡crear hummus! ¿Y qué hacer con demasiado jugo? Por supuesto, la resquesta es simple. ¡Usarlo todo! <Smile type=":p" /></p>
      </div>,
      ru: <div className="recipe-description">
        <p>У меня была банка нута и всё остальные ингредиенты для хумуса. Слишком много лимонного сока… но это не проблема. Ответ: лимонный хумус! <Smile type=":)" /></p>
      </div>,
      pt: <div className="recipe-description">
        <p>Na dispensa, eu tinha uma lata de grão de bico e um monte de suco de limão. Resposta? Homus de limão <Smile type=";)" /></p>
      </div>,
    },

    ingredients: [
      {...ingredients.chickpeas,
        size: 2.5,
        measure: measures.cup,
        guide: {
          en: (scale) => <p>
            {disp_num(scale)} standard U.S. (14 oz.) can{addS(scale)}
          </p>,
          es: (scale) => <p>
            O la cantidad en {disp_num(scale)} lata{addS(scale)} estadounidense{addS(scale)} estándar{addS(scale, "", "es")} (de 14 onzas).
          </p>,
          ru: (scale) => <p>
            {disp_num(scale) + " "}
            {russian_plural(
              scale,
              "стандартная американская банка",
              "стандартные американские банки",
              "стандартных американских банок"
            )} нута
          </p>,
          pt: (scale) => <p>
            {disp_num(scale)} lata{addS(scale)} estadunidense{addS(scale)} padr{addS(scale, "ão", "ões")}
          </p>,
        }
      },
      {...ingredients.oliveOil,
        size: 0.5,
        measure: measures.tbsp,
        kcal_mult: 1.5,
      },
      {...ingredients.lemonJuice,
        size: 0.5,
        measure: measures.cup,
        kcal_mult: 30, // 61 calories in a cup, 1 calorie in a tsp (default)
      },
      {...ingredients.garlic,
        size: 1,
        measure: measures.clove,
        guide: {
          en: (scale) => <p>
            Or {disp_num(scale)} tsp{addS(scale)} minced
          </p>,
          es: (scale) => <p>
            O {disp_num(scale)} cucharada{addS(scale)} 
          </p>,
          ru: (scale) => <p>
            Или {disp_num(scale) + " "} {russian_plural(scale, measures.tsp.name.ru, measures.tsp.plural.ru, measures.tsp.plural.ru_mult)} измельченный чеснок
          </p>,
          pt: (scale) => <p>
            Ou {disp_num(scale)} colher{addS(scale, "", "es")} de chá de alho picado
          </p>,
        },
      },
      {...ingredients.tahini,
        size: 1,
        measure: measures.tbsp,
        kcal_mult: 3,
      },
      {...ingredients.cuminPowder,
        size: 1,
        measure: measures.tbsp,
        kcal_mult: 3,
        guide: {
          en: (scale) => <p>
            You can also use whole seeds <Smile type=";)" />
          </p>,
          es: (scale) => <p>
            También puedes utilizar semillas enteras <Smile type=";)" />
          </p>,
          ru: (scale) => <p>
            Также можно использовать целые семена <Smile type=";)" />
          </p>,
          pt: (scale) => <p>
            Também você pode usar sementes inteiras <Smile type=";)" />
          </p>,
        },
      },
    ],

    steps: [
      {
        directions: {
          en: (scale) => <p>Put everything into a blender and… <i>whizz!!!</i> Yes – it’s really that easy! <Smile type=":p" /></p>,
          es: (scale) => <p>Pon todo en una licuadora, y enciéndela. ¡Sí – es realmente tan fácil <Smile type=":p" />!</p>,
          ru: (scale) => <p>Поставьте всё в блендер, и включите! Да – это так просто  <Smile type=":p" /></p>,
          pt: (scale) => <p>Coloque tudo no liquidificador. Ligue! É realmente assim tão simples <Smile type=":p" /></p>,
        }
      },
      {
        optional: true,
        directions: {
          en: (scale) => <p>To give it some extra fluff, you can add 1-2 tbsps of water and blend again! <Smile type=":)" /></p>,
          es: (scale) => <p>Para un hummus más esponjoso, puedes agregar una o dos cucharadas de agua, y volver a encender la licuadora <Smile type=":)" /></p>,
          ru: (scale) => <p>Для более легкого хумуса, можно добавьте столовую ложку воды (или две), и включите блендер снова <Smile type=":)" /></p>,
          pt: (scale) => <p>Se você quer um homus mais fofo, você pode adicionar um ou dois colheres de sopa de agua, e ligar o liquidificador de novo <Smile type=":)" /></p>,
        }
      },

    ],
  },
/*  {
    name: "Soy Sphghetti",
    image: "soy-spaghetti.jpg",
    description: <div className="recipe-description">
      <p>I made this in Brazil (because my hosts had “spaghetti” made of soy <Smile type=";)" />) but it was inspired by my soon-to-be Couchsurfing host in California, an Indian man who – like me – loves to cook, though his recipes aren’t what I expected! Rather than cook Indian food, he loves Italian! <Smile type=":p" /> So he kept sending me WhatsApp pictures of delicious-looking (though, admittedly, non-vegan <Smile type=":o" />) Italian food, which prompted me to make this <Smile type=":)" /></p>
    </div>,
    ingredients: [
      {...ingredients.soySpaghetti,
        size: 40,
        measure: "gram",
        guide: (scale) => <p>(About <Smile type=";)" size="1em" />)</p>,
        kcal_mult: 0.5,
      },
      ...saltOnionGarlic(1, 0.5, 1),
      {...ingredients.oliveOil,
        size: 0.5,
        measure: "tbsp",
      },
      {...ingredients.oregano,
        size: 0.5,
        measure: "tbsp",
        guide: (scale) => "You can use fresh oregano. (It’s probably better that way!) If you do, just use double or triple and add it in when you add in the spaghetti, not earlier.",
      },
      {...ingredients.aubergine,
        size: 0.5,
        form: "cubed",
      },
      {...ingredients.carrot,
        size: 0.5,
        modifier: "large",
        guide: (scale) => "Or " + disp_num(scale) + " small.",
        form: "cubed",
      },
      {...ingredients.tomato,
        size: 1,
        form: "cubed",
      },
    ],
    steps: [
      {directions: (scale) => "Put the spaghetti in boiling water."},
      {directions: (scale) => "In another pot, cook the oil, onion, garlic, salt, and herbs for 5 minutes."},
      {directions: (scale) => "Add the rest and cook for anoher five minutes."},
      {directions: (scale) => "Drain the spaghetti and add it to the veggies."},
      {directions: (scale) => <span>Cook for 10-15 minutes more, stirring often so it doesn’t burn <Smile type=";)" size="1em" /></span>},
    ],
  },
  {
    name: "Blueberry Banana Muffins",
    image: "blueberry-banana-muffins.jpg",
    description: <div className="recipe-description">
      <p>I’m the sort of baker who drives my mom crazy. Because, <i>measuring</i>? I tend to think that that’s overrated <Smile type=";)" /> The measurements here are approximate… because I wasn’t always using the measuring spoons when I made these!</p>
    </div>,
    ingredients: [
      {...ingredients.speltFlour,
        size: 0.25,
        measure: "cup",
        guide: (scale) => "If you don’t have this, substitute “regular” flour. (But then it’s not as good!)"
      },
      {...ingredients.oatmeal,
        size: 1,
        measure: "tbsp",
        kcal_mult: 1/16,
        guide: (scale) => "Optional. (If you cut it, reduce the nut milk by "
          + disp_num(scale) + " tbsp" + addS(scale) + " ("
          + disp_num(scale/16) + " cup" + addS(scale/16) + ".)",
      },
      {...ingredients.cacaoButter,
        size: 5,
        measure: "gram",
        kcal_mult: 0.05,
      },
      {...ingredients.sweetener,
        size: 1,
        measure: "tbsp",
      },
      {...ingredients.bakingPowder,
        size: 0.5,
        measure: "tsp",
      },
      {...ingredients.nutMilk,
        size: 0.25,
        measure: "cup",
      },
      {...ingredients.banana,
        form: "mashed",
        size: 0.5,
        modifier: "over-ripe",
      },
      {...ingredients.blueberry,
        size: 0.25,
        measure: "cup",
        guide: (scale) => <div>
          <p>About {showRange(Math.ceil(scale*15), Math.ceil(scale*20))} berr{addS(scale*20, "y", "ies")}.</p>
          <p>You can also use other berries <Smile type=":)" size="1em" /> Try {showRange(Math.ceil(scale*4), Math.ceil(scale*5))} blackberr{addS(scale*5, "y", "ies")} or {showRange(Math.ceil(scale*7), Math.ceil(scale*8))} raspberr{addS(scale*8, "y", "ies")}.</p>
        </div>,
      },
    ],
    steps: [
      {directions: (scale) => "Combine the oatmeal and powders."},
      {
        directions: (scale) => "Melt the cacao butter and mix it into the mashed banana.",
        pointer: (scale) => <p className="recipe-step-pointer">Make sure it’s well mixed so the cacao butter doesn’t cool and congeal when you add the nut milk!</p>
      },
      {directions: (scale) => "Add everything. Mix until just combined."},
      {directions: (scale) => "Bake at 180°C (350°F) for 25-30 minutes."},
      {
        directions: (scale) => "Let it cool for 15 minutes or longer.",
        pointer: (scale) => <p className="recipe-step-pointer">Makes {showRange((scale*2<1) ? 1 : Math.round(scale*2), Math.ceil(scale*3))} muffin{addS(Math.ceil(scale*3))}.</p>,
      },
    ],
  },
  {
    name: "Tropical Oatmeal",
    image: "tropical-oatmeal.jpg",
    description: <div className="recipe-description">
      <p>Here’s my approach to oatmeal in Brazil. Walnuts were expensive, but they have WONDERFUL fruits at bargain prices: papaya, guava, pineapple, passionfruit, starfruit, various types of bananas – and quite a few you’ve probably never even heard of! <Smile type=":o" /> So I adjusted my “European”-style recipe. Here’s the Brazilian version <Smile type=";)" /></p>
    </div>,
    ingredients: [
      {...ingredients.oatmeal,
        form: "dry",
        size: 0.5,
        measure: "cup",
      },
      {...ingredients.soyMilk,
        size: 0.3333,
        measure: "cup",
        guide: (scale) => <span>They don’t have “oat milk” in Brazil – or it’s 3-4 times more expensive – so I enjoy it with soy <Smile type="8)" size="1em" /></span>,
      },
      {...ingredients.soyMilk,
        size: 0.5,
        measure: "cup",
        guide: (scale) => <span>For serving <Smile type=";)" size="1em" /></span>,
      },
      {...ingredients.water,
        size: 0.25,
        measure: "cup",
        guide: (scale) => <span>Or replace with {disp_num(scale/4)} cup{addS(scale/4)} MORE soymilk <Smile type=":p" size="1em" /></span>,
      },
      {...ingredients.tropicalFruits,
        form: "diced",
        size: 1,
        measure: "cup",
      },
    ],
    steps: [
      {directions: (scale) => "Mix EVERYTHING together (minus " + disp_num(scale/2) + " cup" + addS(scale/2) + " soymilk for serving).", pointer: (scale) => <p className="recipe-step-pointer">This is the important step. Cooking everything allows the flavours of the ingredients to blend and meld <Smile type=":)" /></p>},
      {directions: (scale) => "Heat it until it starts to boil."},
      {directions: (scale) => "Reduce the flame to a simmer and cook for 5 minutes."},
      {directions: (scale) => <span>Serve and eat it with MORE soymilk <Smile type=":p" /></span>},
    ],
  },
  {
    name: "Banana Walnut Oatmeal",
    image: "oatmeal.jpg",
    description: <div className="recipe-description">
      <p>This is one of my favourite breakfast foods. It’s delicious, healthy, and simple – so it’s easy to throw together at a moment’s notice. I could eat this every morning if I had to. Which, in my current self-isolation, I essentially DO have to…</p>
    </div>,
    ingredients: [
      {...ingredients.oatmeal,
        form: "dry",
        size: 0.5,
        measure: "cup",
      },
      {...ingredients.oatMilk,
        size: 0.5,
        measure: "cup",
        guide: (scale) => <span>You can use any other nut milk. I also like almond <Smile type=":)" size="1em" /></span>,
      },
      {...ingredients.water,
        size: 0.25,
        measure: "cup",
        guide: (scale) => "Or replace with " + disp_num(scale/4) + " more cup"
          + addS(scale/4) + " nut milk.",
      },
      {...ingredients.banana,
        form: "sliced",
        size: 1,
        modifier: "large",
      },
      {...ingredients.walnut,
        form: "pieces",
        size: 1,
        measure: "tbsp",
        guide: (scale) => "About " + disp_num(scale*2)
          + " nut" + addS(scale*2) + " (" + disp_num(scale*4)
          + "walnut hal" + addS(scale*4, "f", "ves") + ")",
        kcal_mult: 1/16,
      },
    ],
    steps: [
      {directions: (scale) => "Mix EVERYTHING together.", pointer: (scale) => <p className="recipe-step-pointer">This is the important step. Cooking everything allows the flavours of the ingredients to blend and meld <Smile type=":)" size="1em" /></p>},
      {directions: (scale) => "Heat until it starts to boil over."},
      {directions: (scale) => "Reduce the flame to a simmer and cook for a minute or two."},
      {directions: (scale) => "Let it cool and enjoy!"},
    ],
  },
  {
    name: "Roasted Root Vegetables",
    image: "rootvegetables.jpg",
    description: <div className="recipe-description">
      <p>This is based on a recipe I was taught by a cousin in San Diego. Only she used just one vegetable: carrots. And she didn’t cut them, but roasted them whole, after slathering them in butter <Smile type=":o" /></p>
    </div>,
    ingredients: [
      {...ingredients.rootVegetables,
        form: "cubed",
        size: 1,
        measure: "cup",
        guide: (scale) => "Some suggestions: potatoes, carrots,"
          + " beets, parsnips, turnips, rutabagas. ("
          + disp_num(scale) + " cup" + addS(scale)
          + " is about " + disp_num(scale/2) + " carrot" + addS(scale)
          + " and " + disp_num(scale/4) + " rutabaga" + addS(scale/4)
          + "/large beet" + addS(scale/4) + "/turnip" + addS(scale/4) + ".)",
      },
      {...ingredients.oliveOil,
        size: .5,
        measure: "tbsp",
      },
      {...ingredients.salt,
        size: 1,
        measure: "tsp",
      },
      {...ingredients.sugar,
        size: 1,
        measure: "tsp",
        kcal_mult: 1/3,
      },
      {...ingredients.pepper,
        size: 1,
        measure: "tsp",
      },
      {...ingredients.cinnamon,
        size: 1,
        measure: "tsp",
      },
    ],
    steps: [
      {directions: (scale) => "Mix the oil, root vegetables, onion and garlic."},
      {directions: (scale) => "In a separate bowl, mix the rest."},
      {directions: (scale) => "Add the powders to the veggies and mix everything."},
      {directions: (scale) => "Bake at 200°C (400°F) for 25 minutes."},
    ],
  },
  {
    name: "Onion pasta",
    image: "onion-pasta.jpg",
    description: <div className="recipe-description">
      <p>This is the sort of thing that happens when I’m trapped in self-isolation by COVID-19, and I have too much pasta in the cupboard and too many onions <Smile type=";)" /></p>
    </div>,
    ingredients: [
      {...ingredients.pasta,
        form: "dry",
        size: 0.5,
        measure: "cup",
        guide: (scale) => "Smaller noodles like macaroni are best. If you’re using spaghetti, break it up before you cook it.",
      },
      {...ingredients.oliveOil,
        size: 0.5,
        measure: "tbsp",
      },
      ...saltOnionGarlic(0.5, 0.5, 1),
      {...ingredients.leafyGreens,
        form: "sliced",
        size: 1,
        measure: "leaf",
        guide: (scale) => "Use things like chard or collard greens. Or just leave these out.",
        kcal_mult: 0.5},
      {...ingredients.freshHerbs,
        size: 1/3,
        measure: "cup",
        guide: (scale) => <span>Whatever you have <Smile type=";)" size="1em" /> These are optional.</span>},
    ],
    steps: [
      {directions: (scale) => "Boil water. Cook the pasta."},
      {directions: (scale) => "While the pasta’s cooking, stir-fry the garlic and onions."},
      {directions: (scale) => "Add the (drained) pasta, sliced greens, herbs, and salt.", pointer: (scale) => <p className="recipe-step-pointer">Once the pasta’s done, obviously <Smile type=";)" size="1em" /></p>},
      {directions: (scale) => "Stir-fry for another few minutes."},
      {directions: (scale) => <span>Enjoy <Smile type=":)" size="1em" /></span>},
    ],
  },
  {
    name: "Carrot & Potato Curry",
    image: "curry.jpg",
    description: <div className="recipe-description">
      <p>This curry isn’t actually based on anything I ate in Asia. It’s inspired by Indian, Burmese, and Thai restaurants in California! <Smile type="8)" /> But it’s easy to make, and incredibly versatile – just swap out the veggies with whatever’s in your pantry!</p>
    </div>,
    ingredients: [
      {...ingredients.coconutMilk,
        size: 1,
        measure: "cup",
        guide: (scale) => <div>
          <p>You can use the canned stuff too, but I prefer the one in a carton. Fewer calories <Smile type=";)" size="1em" /> I’ve used soymilk too – more protein! <Smile type=";)" size="1em" /></p>
          <p>If you like more sauce in your curry, up it to {disp_num(scale*1.5)} cup{addS(scale*1.5)}. (And use {disp_num(scale*1.5)} teaspoon{addS(scale*1.5)} curry powder.)</p>
        </div>,
      },
      {...ingredients.curryPowder,
        size: 1,
        measure: "tsp",
      },
      {...ingredients.carrot,
        size: 1,
      },
      {...ingredients.potato,
        size: 1,
        modifier: "medium",
        guide: (scale) => "Or " + disp_num(scale/2) + " large, or "
          + disp_num(scale*2) + "-" + disp_num(scale*3) + " tiny.",
      },
      ...saltOnionGarlic(0.25, 0.5, 1),
      {...ingredients.bokChoy,
        form: "diced",
        size: 3,
        measure: "leaf",
        guide: (scale) => "Only use the white (bottom) part. Things like Chinese (Napa) cabbage work well too.",
      },
    ],
    steps: [
      {
        directions: (scale) => "Put about "
          + disp_num((scale > 1) ? 0.5 : 0.25)
          + " cup of coconut milk in a pan."
          + " Boil it with the onions and garlic.",
        pointer: (scale) => <p className="recipe-step-pointer">If it dries up, add more from the carton, or (if you’re calorie-conscious) add {(scale>2) ? disp_num(0.5) : disp_num(scale/4)} cup{scale <= 2 ? addS(scale/4) : ""} water.</p>,
      },
      {directions: (scale) => "Mix in the curry powder."},
      {directions: (scale) => "Add the rest of the coconut milk. "
        + "Bring to a boil."},
      {
        directions: (scale) => "Add the potato" + addS(scale, "", "es")
          + ", carrot" + addS(scale) + ", and bok choy."
      },
      {
        directions: (scale) => "Cook for 15-20 minutes.",
        pointer: (scale) => <p className="recipe-step-pointer">Simmer longer to thicken the sauce.</p>,
      },
      {directions: (scale) => "Let it cool and enjoy!"},
    ],
  },
  {
    name: "Pakistani Daal",
    image: "pakistani-daal.jpg",
    description: <div className="recipe-description">
      <p>In Urdu (and Hindi), “daal” just means lentils. It’s a popular dish in South Asian cuisine – and one that I always knew, in Pakistan, would be vegan!</p>
      <p>I’m sure the Pakistani version is similar to the Indian one. At least, to what I ate at restaurants in India. But Indian daals often had a lot of milk or cream, and were <i>not</i> vegan. This Pakistani recipe isn’t quite what I ate in Sindh and Punjab, but it’s as close as I can make it!</p>
    </div>,
    ingredients: [
      ...saltOnionGarlic(0.25, 1, 1),
      {...ingredients.oliveOil,
        size: 0.5,
        measure: "tbsp",
      },
      {...ingredients.lentils,
        size: 0.5,
        measure: "cup",
      },
      {...ingredients.water,
        size: 1.5,
        measure: "cup",
      },
      {...ingredients.tomato,
        size: 1,
        modifier: "ripe, even over-ripe",
      },
      {...ingredients.tomatoPaste,
        size: 1,
        measure: "tsp",
      },
      {...ingredients.garamMasala,
        size: 0.5,
        measure: "tbsp",
        guide: (scale) => <div>
          <p>If you don’t have this, you can make it by combining things like cardamom, tumeric, allspice, black pepper, and chile powder. There’s no exact recipe for “garam masala”; everyone’s is slightly different.</p>
          <p>I like the Whole Foods version because it’s NOT spicy! <Smile type=";)" size="1em" /></p>
        </div>,
      },
    ],
    steps: [
      {
        directions: (scale) => "Boil the lentils in the water. Keep them going while you do everything else. They need to cook at least an hour.",
        pointer: (scale) => (scale <= 2)
          ? null
          : <p className="recipe-step-pointer">Even though the recipe scales to {disp_num(scale*1.5)} cups of water, you don’t want to over-do it! For {disp_num(scale)} servings, try {disp_num(scale * .75)} cups of water.</p>,
      },
      {directions: (scale) => "Fry the onions and garlic in the oil while you prepare the tomato."},
      {directions: (scale) => "Add the tomato and tomato paste."},
      {directions: (scale) => "Mix in the salt and garam masala. Now cook the whole thing for 3-5 minutes."},
      {directions: (scale) => "If the lentils aren’t done, turn off the flame and wait until they are. When everything’s ready, pour the lentils (daal) into the onion/tomato/masala mix and bring to a boil. Simmer for 5-10 minutes."},
      {directions: (scale) => "Serve with some sort of flatbread. (Lavash and pita work well. Even flour tortillas!)", pointer: (scale) => <p className="recipe-step-pointer">To eat this in true Pakistani fashion, don’t use any cutlery! Scoop up the daal by folding a piece of bread and using that as a spoon of sorts!</p>},
    ],
  },
  {
    name: "Patta Gobhi",
    image: "patta-gobhi.jpg",
    description: <div className="recipe-description">
      <p>When in India, one eats a lot of cabbage ;) Apparently the word for that in Hindi is “patta gobhi”. But I first made this in California <Smile type="8)" /> I had too much cabbage, and I was at a loss. So I looked up “patta gobhi recipes” online – then did some Jane-style imporv – and this is what I ended up with <Smile type=":)" /></p>
    </div>,
    ingredients: [
      {...ingredients.cabbage,
        size: 0.25,
        form: "finely chopped",
        guide: (scale) => "I chopped this in a food processor. Just pulse a couple times, and voilà!",
      },
      {...ingredients.coconutMilk,
        size: 0.25,
        measure: "cup",
      },
      {...ingredients.garamMasala,
        size: 0.5,
        measure: "tbsp",
      },
      ...saltOnionGarlic(0.5, 0.5, 1),
    ],
    steps: [
      {directions: (scale) => "Saute the garlic and onions in the coconut milk."},
      {directions: (scale) => "Mix in the salt and garam masala."},
      {
        directions: (scale) => "Add the cabbage. Cook well.",
        pointer: (scale) => <p className="recipe-step-pointer">I cheated and used a pressure cooker. 5 minutes on normal pressure cooked it well.</p>,
      },
      {directions: (scale) => <p>Let it cool and enjoy <Smile type=":)" /></p>},
    ],
  },
  {
    name: "Cauliflower Olive Dip",
    image: "cauliflower-olive-dip.jpg",
    description: <div className="recipe-description">
      <p>I first made this when I was on a strict diet because I had to lose weight for a gut operation. But I liked it so much I keep on making it <Smile type=":)" /></p>
    </div>,
    ingredients: [
      {...ingredients.cauliflower,
        size: 1/3,
        modifier: "medium",
        kcal_mult: 1/3,
      },
      {...ingredients.olive,
        size: 0.25,
        measure: "can",
        guide: (scale) => "About " + disp_num(scale/2) + " cup"
          + addS(scale/2) + ".",
        kcal_mult: 1,
      },
      {...ingredients.nutMilk,
        size: 0.25,
        measure: "cup",
      },
      {...ingredients.oliveOil,
        size: 1,
        measure: "tsp",
        kcal_mult: 1/3,
      },
    ],
    steps: [
      {directions: (scale) => <p>Steam the cauliflower. The longer the better <Smile type=";)" size="1em" /></p>},
      {directions: (scale) => "Put everything in a food processor."},
      {directions: (scale) => "Blend it all together for 2-3 minutes."},
      {
        directions: (scale) => <span>Serve with dippable things <Smile type=":)" /></span>,
        pointer: (scale) => <p className="recipe-step-pointer">I like carrots and cucumbers, or dehydrated bits of flat breads like lavash.</p>
      },
    ],
  },
  {
    name: "Choco-banana",
    image: "choco-banana.jpg",
    description: <div className="recipe-description">
      <p>I made this up on the fly and it’s become my favourite dessert <Smile type=":)" /> Super easy to make, too. I hope you love it as much as I do!</p>
    </div>,
    ingredients: [
      {...ingredients.chocolate,
        size: 1,
        measure: "square",
        modifier: "100%",
        kcal_mult: 1/9,
      },
      {...ingredients.banana,
        form: "mashed or sliced",
        size: 1,
        modifier: "large",
        kcal_min: 100,
      },
      {...ingredients.nuts,
        size: 1,
        modifier: "tbsp",
        kcal_mult: 1/16,
      },
      {...ingredients.cacaoButter,
        size: 3,
        measure: "gram",
        kcal_mult: 3/100,
      },
    ],
    steps: [
      {directions: (scale) => "Melt the cacao butter in a frying pan."},
      {directions: (scale) => "Add the banana"
        + addS(scale) + " and fry for 1-2 minutes."},
      {directions: (scale) => "If you’re using nuts, mix them in now."},
      {directions: (scale) => "Throw in the chocolate and mix well, until the chocolate’s fully mixed in with the rest."},
      {directions: (scale) => "Refridgerate the whole thing for 1-2 hours."},
    ],
  },
  {
    name: "Roasted Cruciferous Veggies",
    image: "roasted-cruciferous-veggies.jpg",
    description: <div className="recipe-description">
      <p>This is partly based on something I saw my cousin once do, partly based on my dad’s recipe, but mostly it’s just made up by Jane <Smile type=";)" /> A good way to use that extra head of broccoli or cauliflower!</p>
    </div>,
    ingredients: [
      {...ingredients.cauliflower,
        size: 7,
        measure: "floret",
        modifier: "",
        kcal_mult: 1/2,
      },
      {...ingredients.broccoli,
        size: 7,
        measure: "floret",
        modifier: "",
        kcal_mult: 1/2,
      },
      {...ingredients.oliveOil,
        size: 1,
        measure: "tbsp",
      },
      {...ingredients.salt,
        size: 1,
        measure: "tsp",
      },
      {...ingredients.lemonJuice,
        size: 0.5,
        measure: "tbsp",
        guide: (scale) => "Or squeeze " + disp_num(scale/2)
          + " lemon" + addS(scale/2) + " over it.",
      },
      {...ingredients.freshHerbs,
        size: 1/3,
        measure: "cup",
        guide: (scale) => <p>Whatever you have <Smile type=";)" size="1em" /></p>,
      },
    ],
    steps: [
      {directions: (scale) => "Toss everything together in a large mixing bowl."},
      {directions: (scale) => "Roast at 210°C (425°F) for 25-30 minutes."},
    ],
  },
  {
    name: "Vegan Raita",
    image: "vegan-raita.jpg",
    description: <div className="recipe-description">
      <p>Raita is a common yoghurt dish in India. However, despite the number of times I’ve been to India recently, I actually didn’t get this from there <Smile type=":o" /> This came from my parents’ home, while I was there getting my documents sorted. It’s much easier to make soy yoghurts with their equipment <Smile type=";)" /></p>
    </div>,
    ingredients: [
      {...ingredients.veganYoghurt,
        size: 1,
        measure: "cup",
        guide: (scale) => <span>I like coconut <Smile type=";)" size="1em" /></span>,
      },
      {...ingredients.cucumber,
        size: 1,
        form: "finely chopped",
      },
      {...ingredients.coriander,
        size: 1,
        measure: "tbsp",
        guide: (scale) => "Maybe " + showRange(scale*20, scale*30)
          + " lea" + addS(scale*30, "f", "ves") + ".",
      },
      {...ingredients.groundGinger,
        size: 0.5,
        measure: "tsp",
      },
      {...ingredients.cuminSeeds,
        size: 0.5,
        measure: "tbsp",
        kcal_mult: 1.5, // 0.5 tbsp = 1.5 tsp
      },
    ],
    steps: [
      {directions: (scale) => <span>Mix everything together. Yes, it’s that easy <Smile type=";)" />{" "}<Smile type=":o" /></span>},
    ],
  },
  {
    name: "Baingan Bharta",
    image: "baingan-bharta.jpg",
    description: <div className="recipe-description">
      <p>Like many of the Indian recipes on here, I didn’t actually taste this in India <Smile type=":/" /> I had some extra eggplant while trapped by COVID, and I figured, there’s probably an Indian recipe that uses this. There is! Thanks, internet <Smile type=":p" /></p>
    </div>,
    ingredients: [
      {...ingredients.veganYoghurt,
        size: 0.5,
        measure: "cup",
      },
      ...saltOnionGarlic(0.25, 0.5, 0.25),
      {...ingredients.groundGinger,
        size: 1,
        measure: "tsp",
      },
      {...ingredients.garamMasala,
        size: 0.5,
        measure: "tbsp",
      },
      {...ingredients.coriander,
        size: 0.25,
        measure: "bunch",
        guide: (scale) => "Maybe " + showRange(scale*20, scale*30)
          + " lea" + addS(scale*30, "f", "ves") + ".",
      },
      {...ingredients.groundGinger,
        size: 0.5,
        measure: "tsp",
      },
      {...ingredients.oliveOil,
        size: 0.5,
        measure: "tbsp",
      },
      {...ingredients.aubergine,
        size: 1,
      },
      {...ingredients.cuminSeeds,
        size: 1,
        measure: "tsp",
      },
    ],
    steps: [
      {directions: (scale) => "Pre-cook the eggplant" + addS(scale)
        + " by roasting at 230°C (450°F) for 20 minutes. After "
        + addS(scale, "it cools", "they cool") + " peel and cut "
        + addS(scale, "it", "them") + "."},
    ],
  },*/
];
