import React from 'react';
import Smile from '../Smile';
import ReadMore from '../ReadMore';
import ChLang from '../ChLang';

// ’ “” – …
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
// ё — «» …
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …

export const train = {
  url: "train",
  folder: true,
  title: {
    en: "A train across America",
  },
  description: {
    en: <p>I was flown on an emergency (and expensive!) U.S. government flightfrom Karach, Pakistan, not to California, where my frantic parents awaited me, but to Washington, D.C. How to get to California? How about a cross-country train trip, right in the middle of a blossoming pandemic? <Smile type=";)" /> <Smile type=":o" /></p>,
  },
  photos: [
    {
      url: "chicago-street-art",
      title: {
        en: "Chicago Street\u00A0Art",
      },
    },
    {
      url: "albuquerque-mural",
      title: {
        en: "An\u00A0Albuquerque Mural",
      },
      description: {
        en: <p>Seeing as I have one of street art in Chicago, I figured, why not do New Mexico as well? <Smile type=":p" /></p>,
      }
    },
    {
      url: "the-west",
      title: {
        en: "The\u00A0Great American\u00A0West",
      },
    },
    {
      url: "when-california-is-green",
      title: {
        en: "When California Is Green",
      },
      description: {
        en: <p>This was taken out of a train near San Luis Obispo, but it could really be anywhere along the California coast. <ReadMore lang="en">The state only glows alive like this for one or two months a year. But when it does, it’s magnificent <Smile type=":)" /></ReadMore></p>,
      },
    },
  ],
};
