import React from 'react';
import './Ingredient.css';

function getKcal(ingredient, scale) {
  if (scale === undefined) {scale = 1;}
  var mult = (ingredient.kcal_mult)
    ? ingredient.kcal_mult
    : ingredient.size;
  if (ingredient.kcal !== undefined) {
    return Math.round(ingredient.kcal*mult*scale);
  }
  const min = Math.round(ingredient.kcal_min*mult*scale);
  const max = Math.round(ingredient.kcal_max*mult*scale);
  return (min === max) ? min.toString() : min + "-" + max;
}

/* React doesn't like this but I'm adding a method to all numbers ;)
   So there's no problem with floats not being exactly equal.
   (I don't know if this is an issue in Javascript, but it is in C++...)
 */
// eslint-disable-next-line
Number.prototype.closeTo = function(value, range=0.001) {
  return Math.abs(this.valueOf()-value) < range;
};

export function disp_num(num) {
  var frac = num - Math.floor(num);
  var dispFrac = "";
  if (frac.closeTo(1/2, 0.01)) {dispFrac = "½";}
  else if (frac.closeTo(1/3, 0.01)) {dispFrac = "⅓";}
  else if (frac.closeTo(1/4, 0.01)) {dispFrac = "¼";}
  else if (frac.closeTo(2/3, 0.01)) {dispFrac = "⅔";}
  else if (frac.closeTo(3/4, 0.01)) {dispFrac = "¾";}
  else if (frac.closeTo(1/6, 0.01)) {dispFrac = "⅙";}
  else if (frac.closeTo(5/6, 0.01)) {dispFrac = "⅚";}
  else if (frac.closeTo(1/8, 0.01)) {dispFrac = "⅛";}
  else if (frac.closeTo(3/8, 0.01)) {dispFrac = "⅜";}
  else if (frac.closeTo(5/8, 0.01)) {dispFrac = "⅝";}
  else if (frac.closeTo(7/8, 0.01)) {dispFrac = "⅞";}
  else if (frac.closeTo(1/9, 0.005)) {dispFrac = "⅑";}
  // If it's not a standard fraction, display it as a decimal
  else {
    var formatted = Math.round(num*100)/100;
    return formatted.toString();
  }

  if (Math.floor(num) === 0) {
    return dispFrac;
  }
  else {
    return Math.floor(num).toString() + dispFrac;
  }
}

function scaleAmericanUnits(unit, scale) {
  if (scale < 1) {
    if (unit === "cup" && scale <= 1/8) {
      scale *= 16;
      unit = "tbsp";
    }
    if (unit === "tbsp" && (scale <= 1/3 || scale.closeTo(2/3))) {
      scale *= 3;
      unit = "tsp";
    }
  }
  else if (scale > 1) {
    if (unit === "tsp" && (scale === 3 || scale > 5)) {
      scale /= 3;
      unit = "tbsp";
    }
    if (unit === "tbsp" && (scale === 4 || scale >= 8)) {
      scale /= 16;
      unit = "cup";
    }
  }
  return {size: scale, measure: unit};
}

const measure_plurals = {
  leaf: "leaves",
};

function Ingredient(props) {
  const {lang, showKcal, scale} = props;
  const ingredient = props.data;
  var {measure} = ingredient;
  // if (measure) {measure = measure[lang];}
  var size = ingredient.size * scale;
  if (size.closeTo(1)) {size = 1;}
  else if (measure) {
    // const scaleUnits = scaleAmericanUnits(measure, size);
    // measure = scaleUnits.measure;
    // size = scaleUnits.size;
  }
  const disp_size = disp_num(size);
// ##############
  const disp_name = ((ingredient.plural && ingredient.plural[lang]) && (measure || ((size > 1 || size === 0 || disp_size.length > 1) && !measure)))
    ? ingredient.plural[lang]
    : ingredient.name[lang];
  var disp_measure = (ingredient.modifier && ingredient.modifier[lang])
    ? ingredient.modifier[lang]
    : "";
console.log(ingredient);
  if (measure && measure.name && measure.name[lang]) {
    if (lang === "ru") {
      if (size % 10 === 1) {
        disp_measure += measure.name.ru;
      }
      else if ((size % 10 < 5 && (size%1).closeTo(0)) || size < 1) {
        disp_measure += (measure.plural && measure.plural.ru)
          ? measure.plural.ru
          : measure.name.ru + "а";
      }
      else {
        disp_measure += (measure.plural && measure.plural.ru_mult)
          ? measure.plural.ru_mult
          : measure.name.ru + "ов";
      }
    }
    else {
      disp_measure += (disp_size.length === 1 && size <= 1 && size !== 0)
        ? measure.name[lang]
        : (measure.plural && measure.plural[lang])
          ? measure.plural[lang]
          : measure.name[lang] + "s";
    }
  }
  const disp_form = (ingredient.form && ingredient.form[lang])
    ? "(" + ingredient.form[lang] + ")"
    : null;
  const disp_guide = (ingredient.guide && ingredient.guide[lang])
    ? <p className="recipe-ingredient-guide">
      {ingredient.guide[lang](scale)}
    </p>
    : null;
  const kcalCount = (showKcal) ? getKcal(ingredient, scale) : undefined;
  var calories_word = "calories";
  const cals = Number(kcalCount);
  if (lang === "en" && cals === 1) {calories_word = "calorie";}
  if (lang === "es") {
    calories_word = (cals === 1) ? "caloría" : "calorías";
  }
  if (lang === "es") {
    calories_word = (cals === 1) ? "caloria" : "calorias";
  }
  if (lang === "ru") {
    calories_word = "calorie";
  }
  if (lang === "en" && cals === 1) {calories_word = "calorie";}
  const disp_kcal = (showKcal)
    ? "– " + kcalCount + " " + calories_word
    : null;
  return <li className="ingredient">
    <div className="ingredient-img-div">
      <img
        src={"/images/cooking/ingredients/" + ingredient.image}
        alt=""
      />
    </div>
    <div className="ingredient-div">
      {disp_size} {" "}
      {disp_measure} {" "}
      {disp_name} {" "}
      {disp_form} {" "}
      {disp_kcal}
    </div>
    {disp_guide}
  </li>;
}

export default Ingredient;
