import React from 'react';
import Smile from '../Smile';
import ReadMore from '../ReadMore';
import ChLang from '../ChLang';

export const brazil = {
  url: "brazil",
  folder: true,
  title: {
    en: "Brazil during COVID",
    es: "Mi viaje a Brasil durante la pandemia",
    // ru: "Бразилия, во время коронавируса",
    pt: "Brasil, apesar da pandemia",
  },
  description: {
// ’ “” – …
    en: (funcs) => <div className="folder-description">
      <p>2020 – and the world was changing. Travel (at least for U.S. citizens) used to be a simple matter of flashing a U.S. passport and most countries would let you in. Now there were tests required, time limits, restrictions on layovers… and words like “PCR” had entered the common lexicon. (But I bet you didn’t know it stands for “polymerase chain reaction” – or what <i>that</i> means! <Smile type=":p" />)</p>
      <p>One place, however, where that was <i>not</i> the case was Brazil. Throughout 2020, Brazil remained wide open, not even requiring a COVID test for entry – at least not by August. Thank you, Jair Bolsonero. <Smile type=";)" /> Better, the visa fee for U.S. citizens ($160 or so <Smile type=":o" />) had just been abolished. <Smile type=":D" /></p>
      <p>So I bought round-trip plane tickets from L.A. to São Paulo for somethng like $300. <Smile type=":o" /> <Smile type=":)" /> I guess there’s a silver lining to everything, even a global pandemic!</p>
      <p>And then I started to learn <ChLang lang="pt" f={funcs.chlang}>Portuguese</ChLang>. Which was a <i>far</i> easier language than <ChLang lang="ru" f={funcs.chlang}>some of the others</ChLang> I’d studied. I already spoke <ChLang lang="es" f={funcs.chlang}>Spanish</ChLang>, and the words and grammar are nearly identical. I started with Duolingo, but soon switched to just watching a Portuguese TV show (“3%”) on Netflix, with Portuguese subtitles, pausing it often, backtracking to hear each sentence five times. Because of the similarities to Spanish, I was already able to understand most <i>written</i> Portuguese! <Smile type=":p" /> I just needed listening practice.</p>
      <p>So, bottom line, most of this isn’t available in English. <Smile type=":o" /> Sorry! Try <ChLang lang="pt" f={funcs.chlang}>the local language</ChLang>, or <ChLang lang="es" f={funcs.chlang}> the one that I actually used</ChLang> to converse with most of my hosts <Smile type=";)" /> – my Portuguese still being fairly inadequate for conversation.</p>
      <p>So that was my first trip to Brazil: September of 2020. <Smile type=":p" /> I’m sure that there’ll be more – e agora eu posso falar <ChLang lang="pt" f={funcs.chlang}>o idioma</ChLang>, pelo menos coloquialmente! <Smile type="8)" /></p>
    </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
    es: (funcs) => <div className="folder-description">
      <p>Nunca pensaba que yo pudiera ir a Brasil. Ni en mis sueños <Smile type=";)" /> Despues de que el coronavirus cerró todo el mundo, me sentí bien atrapada en California, donde viven mis padres. <Smile type=":(" /></p>
      <p>Pero, ¿Brasil? ¿Sudamérica? No creía que fuera posible viajar tan lejos en esta época. Y, además, considero que mi vida inició de nuevo en 2020. En 2019 había viajado bastante… a la India, a las Filipinas, a Australia y Nueva Zelandia, Canadá y México y los Estados Unidos. Pero me siento casi como si esa fuera una persona diferente. Esas viajes no cuentan.</p>
      <p>Los que cuentan empiezan en 2020. Tal vez en marzo. Todavía me encantaba viajar, pero mi mentalidad había cambiado un poco. ¡No me importaba tanto visitar cada país del mundo <Smile type=":o" />! Pero sí, todavía me gusta viajar, y todavía quiero visitar todos los continentes. De este año, adelante.</p>
      <p>Cuando vino algo que nadie anticipaba. El corona <Smile type=":k" /></p>
      <p>Ya había visto un poco del mundo. Mi orden de visitar los continentes de nuevo fue así:</p>
      <ol>
        <li>Asia – empecé en la India</li>
        <li><i>Africa – sólo porque mi avión de evacuación, de Pakistan a los EE UU, aterrizó brevemente en Egipto <Smile type=";)" /></i></li>
        <li>América del norte – por la pandemia</li>
        <li>¿Europa?</li>
      </ol>
	  <p>Al menos, suponía que mi próxima continente sería Europa. Mi hermana estaba en Londres. Mis padres habían pagado por, y después cancelado, muchos vuelos a Ingleterra. Europa quedaba carrada. Nosotros estábamos parilizados. Nadie pensaba que la pandemia duraría tanto tiempo. <Smile type=":(" /></p>
      <p>Cuando yo descubrí vuelos bastante baratos, de ida y vuelta, entre Los Ángeles y Brasil. <Smile type="8)" /></p>
      <p>(¿Quieres más? Léelo en <ChLang lang="pt" f={funcs.chlang}>portugués</ChLang>. <Smile type=":p" />)</p>
    </div>,
// ё — «» …
    ru: (funcs) => <div className="folder-description">
      <p>Пока я была в Бразилии я не говорила по-русски. Может я теряала мой русскии язык… <Smile type=":(" /> Большая часть этого на <ChLang lang="pt" f={funcs.chlang}>португальском</ChLang> или <ChLang lang="es" f={funcs.chlang}>испанском</ChLang>. <Smile type=":p" /></p>
    </div>,
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …
    pt: (funcs) => <div className="folder-description">
      <p>Antes de ir ao Brasil, eu falava quase nada de português…</p>
      <p>Assim, por que Brasil? Porque o mundo tenia uma pandemia, e o Brasil foi quase o único país que for aberto. <Smile type=":p" /></p>
      <p>Além disso, eu sim quis ir para essa região. Uma das protagonistas das minhas conta não publicadas (com que eu identifico muito) é brasileira. Mas eu não sabia a primeira coise sobre Brasil! <Smile type=":o" /></p>
      <p>Em Setembro, tudo isso trocou. <Smile type=":p" /> Agora eu conheço o país – ou, pelo menos, os estados de Rio de Janeiro e São Pablo. <Smile type=":p" /> Apesar de eu não falar muito português quando eu estava lá, eu acredito que eu aprendí a língua, e posso falar nela se preciso. Até pôr quase todos as minhas fotos neste idioma! (E alguns em <ChLang lang="es" f={funcs.chlang}>espanhol</ChLang>, o quê e a língua que eu utilizava pela cominicação com os meus anfitriões <Smile type=";)" />.)</p>
      <p>Mas eu estou segura que eu voltará logo! Gostei muito tudo disso, e agora tenho alguns amigos brasileiros (incluso pelo internet quem eu conheci depois de sair <Smile type=":o" />) quem eu preciso visitar. Até a minha proxima viagem! <Smile type=":)" /></p>
    </div>,
  },
  photos: [
    {
      url: "a-plane-pic-over-nevada",
      title: {
        en: "A\u00A0Plane\u00A0Pic Over\u00A0Nevada",
      },
      description: {
        en: <p>Apparently it <i>is</i> possible to take cool pictures from a plane <Smile type=":p" /></p>
      },
    },
    {
      url: "sunny-florida",
      title: {
        en: "Sunny Florida",
        es: <span>La{"\u00A0"}Florida{"\u00A0"}es multicolor{"\u00A0"}<Smile type=";)" /></span>
      },
      titleText: {
        es: "La Florida es multicolor",
      },
    },
    {
      url: "air-travel-in-2020",
      title: {
        en: "Air\u00A0Travel in\u00A02020",
        es: "Los\u00A0aviones durante la\u00A0pandemia",
        ru: "Пустой самолет",
        pt: "Um\u00A0avião\u00A0– setembro\u00A0de\u00A02020",
      },
      description: {
        en: <p>From Miami to São Paulo.</p>,
        ru: <p>В 2020 году, люди боятся самолетов <Smile type=";)" /></p>,
        pt: <p>De Miami para São Paulo.</p>,
      },
    },
    {
      url: "the-homeless-in-brazil",
      title: {
//        en: "The\u00A0homeless in\u00A0Brazil",
//        ru: "Бездомный во\u00A0время коронавирус",
        pt: "Brasil,\u00A0ou Califórnia?",
      },
      description: {
        pt: <p>Apesar de isso ser uma escena da cidade de São Paulo, me lembra do estado onde moram os meus pais nos EUA: Califórnia. <ReadMore lang="pt">Cá as ruas são quase o mesmo, com pessoas sem lar vivendo em tendas. Muitos ciudadanos dos EUA acreditam que eles são melhores que as pessoas que vivem em outros países <Smile type=":/" /> Obviamente, isso não é verdade…</ReadMore></p>,
      },
    },
    {
      url: "a-divided-beach",
      title: {
        es: "Una\u00A0playa dividida",
        pt: "Uma\u00A0praia dividida",
      },
      description: {
        es: <p>Yo fui a esta playa dos veces <ReadMore lang="es">— porque las personas que viven aqui (en el edificio desde que esta foto fue tomada) son tan increíbles que me invitaron a venir de nuevo después de que yo les abandoné para ir a Rio de Janeiro <Smile type=":p" /> Mantenemos contacto, ¡y ahora ellos aun me van a ayudar con la sección portuguesa de este sitio! He descubierto unos verdaderos amigos aquí en Brasil <Smile type=":D" /> ¡Un grito para Letícia y Joabe de Praia Grande <Smile type="8)" />!</ReadMore></p>,
        pt: <p>O nome desta praia diz todo. Chama-se “Praia Grande”. <ReadMore lang="pt">E, sim, é bastante grande <Smile type=";)" /> Eu foi invitada cá por umas pessoas incríveis que encontrou num sítio web, <a href="https://www.workaway.info" target="_blank" rel="noopener">Workaway</a> (Trabalhar fora). Mas o trabalho estava muito claro, e principalmente eu ficava livre para explorar o ambiente maravilhoso. Mas eu deveria ter saído este dia. Foi o dia da independência do Brasil… mas eu só aprendi isso depois de desperdíciar todo o dia! <Smile type=":o" /> <Smile type=":(" /></ReadMore></p>,
      },
    },
    {
      url: "edificio-mumificado",
      title: {
        pt: "Edifício mumificado",
      },
    },
    {
      url: "busking-during-covid",
      title: {
        pt: "O\u00A0corona\u00A0não\u00A0para de\u00A0artistas\u00A0de\u00A0rua"
      },
    },
    {
      url: "chillin",
      title: {
        es: "La vida lenta",
        ru: "Человечество во\u00A0время COVID",
        pt: "Com\u00A0as máscaras",
      },
      description: {
        es: <p>A pesar de la pandemia <Smile type=":p" /></p>
      }
    },
    {
      url: "ipanema",
      title: {
        es: "Ipanema",
        pt: "Ipanema",
      },
      description: {
        es: <p>Mientras el coronavirus destruye la humanidad, el océano sigue como siempre <Smile type=":/" /></p>,
      },
    },
    {
      title: {
        es: "Paraty",
        pt: "As\u00A0ruas\u00A0velhas da\u00A0América",
      },
      url: "colonial-streets",
      description: {
        es: <p>Yo sólo aprendí sobre este lugar por mi anfitriona de Couchsurfing, quien vive en Rio. (Y viaja bastante – hasta hoy en día yo sigo su Instagram. <Smile type=":p" />) <ReadMore lang="es">Me dijo, justo antes de que yo llegara a su casa, que ella estaba visitando la ciudad de Paraty. «Paraty—» pensé yo. «¿Qué es esto?» Decidí yo misma visitar. <Smile type="8)" /> ¡Y, absolutamente, valió la pena <Smile type=":D" />!</ReadMore></p>,
        pt: <p>Brasil: mais velho que os EUA <Smile type=";)" /></p>,
      },
    },
  ],
};
