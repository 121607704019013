import React from 'react';
import Smile from '../Smile';

// ’ “” – …
export const welcome = {
  title: {
    en: "Welcome to My Life",
  },
  url: "welcome",
  img: "my-life",
  country: "USA",
  state: "Louisiana",
  city: "Lafayette",
  post: {
    en: <div id="main-post-text">
      <p>This is my first post, so let’s start with where I’m at now. And this is a travel blog, right? So where am I travelling?</p>
      <p>It’s not always like that. I’m settled right now, living in a friend’s house in Lafayette, Louisiana, working from my laptop and fasting for Ramadan. I don’t have any travel plans for at least the next week.</p>
      <p>So what’s it like here in Lafayette? Yesterday for iftar (the big dinner each Ramadan evening that breaks the fast) I dined at another friend’s house, a grad student at the University of Louisiana. He cooked amazing food for all of us – including not one or two but <i>four</i> vegan dishes for Aisha (me). I knew his food was good (he runs a Saturday bakery at the farmers market with his roommate, where I’ve sampled their delicacies) but I hadn’t realised <i>how</i> good he was! His cake (all vegan!) was the best dessert I’ve had in months. <Smile type=":D" /></p>
      <p>We ate together, played social word games, and talked about the various countries we’re from (Azerbaijan, the USA, and Egypt) when a friend’s cousin came by who was visiting from Cairo. (Yes, we were hanging out indoors, but we’re all vaccinated for COVID <Smile type="8)" /> If you’re not, get the shot!)</p>
      <p>Sounds like a fairly normal meeting of college-age friends, right?</p>
      <p>Well, not quite. This is where it gets weird…</p>
      <p>A month ago, I’d never been to Louisiana. A month ago, I didn’t know any of these people. <Smile type=":o" /> A month ago, I was living in Querétaro, Mexico, with the sister and then the mother of a friend I’d met in Houston about two weeks before that <Smile type=":p" /> And my name isn’t “Aisha”; it’s “Jane Sofia”. “Aisha” is a name my hosts gave me in Pakistan when I converted to Islam <Smile type=";)" /></p>
      <p>This is my life. I’m a “digital nomad” – living and working from wherever I can plug in my laptop or phone. I meet people through word of mouth or hospitality exchange sites like Workaway or Couchsurfing. And, for a brief moment, I try to join their communities as one of their own. (Trying and sometimes failing to do no harm to the wonderful people who open their houses to me; I’ll post more on that in the future <Smile type=":/" />)</p>
      <p>Is it safe for a lone woman to do this? Probably not. Street smarts are required. The willingness to leave a host’s house if he makes you uncomfortable, and sleep in a park or some such <Smile type=":o" /> – and the savvy to sleep <i>en plein air</i> without being bothered. It’s not always safe. I’ve never been bothered while trying to catch a few dozen winks, but I <i>have</i> been sexually assaulted while travelling in Pakistan. <Smile type=":k" /> I’ll blog about that later. In short, my lifestyle is <i>not</i> recommended for anyone… but I make it work – and then write about it here <Smile type=";)" /></p>
      <p>So there you go. That’s my life. I’ll live it, and then I’ll share it with y’all – definitely not as a guide or a recommendation, but to show the world that you <i>can</i> do these things. Whatever your gender. I’ve done these things, and I’ve met some great people who I hope will stay friends for life. I’ve done these things, I’ve enjoyed doing them, and I’ll continue to do them.</p>
      <p>Even as a woman. <Smile type=":p" /> <Smile type="8)" /></p>
    </div>,
  },
};
