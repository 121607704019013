import React from 'react';
import Smile from '../Smile';
import ReadMore from '../ReadMore';
import ChLang from '../ChLang';

export const capitols = {
  url: "capitols",
  folder: true,
  title: {
    en: "U.S. State Capitols",
    es: "Capitolios estatales de los EE UU",
    ru: "Столицы штатов США",
    pt: "Capitólios estaduais dos EUA",
    sw: "Majengo ya capitol za majimbo ya Marekani",
  },
  description: {
// ’ “” – …
    en: <div className="folder-description">
      <p></p>
    </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
    es: <div className="folder-description">
      <p></p>
    </div>,
// ё — «» …
    ru: <div className="folder-description">
      <p>Пока путешествия США я понял, что я увидела много столицы штатов. Я собрал много фотографии их. Я решила здесь их показывать. <Smile type=":p" /></p>
    </div>,
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …
    pt: <div className="folder-description">
      <p></p>
    </div>,
    sw: <div className="folder-description">
      <p>Wakati nilisafiri majimbo ya Marekani, niliona majengo ya capitol mengi, ya majimbo mengi. <Smile type=":o" /> Nilianza kufanya ukusanyaji wa majengo. Ninayaonyesha hapa. <Smile type=":p" /></p>
    </div>,
  },
  photos: [
    {
      url: "california",
      title: {
        en: "California",
        es: "California",
        ru: "Калифорния",
        pt: "Califórnia",
        sw: "California",
      },
      description: {
        en: <p>My parents live in the capital of California. Which is a city called Sacramento. <i>Not</i> San Francisco or L.A.! <Smile type=":p" /></p>
      },
    },
    {
      url: "texas",
      title: {
        en: "Texas",
        es: "Texas",
        ru: "Техас",
        pt: "Texas",
        sw: "Texas",
      },
    },
    {
      url: "georgia",
      title: {
        en: "Georgia",
        es: "Georgia",
        ru: "Джорджия",
        pt: "Geórgia",
        sw: "Georgia",
      },
    },
    {
      url: "louisiana",
      title: {
        en: "Louisiana",
        es: "Luisiana",
        ru: "Луизиана",
        pt: "Luisiana",
        sw: "Louisiana",
      },
    },
    {
      url: "tennessee",
      title: {
        en: "Tennessee",
        es: "El Tenesé",
        ru: "Теннесси",
        pt: "Tenessi",
        sw: "Tennessee",
      },
    },
    {
      url: "indiana",
      title: {
        en: "Indiana",
        es: "Indiana",
        ru: "Индиана",
        pt: "Indiana",
        sw: "Indiana",
      },
    },
    {
      url: "colorado",
      title: {
        en: "Colorado",
        es: "Colorado",
        ru: "Колорадо",
        pt: "Colorado",
        sw: "Colorado",
      },
      description: {
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
        es: <p>No sé qué aquí es «colorado», pero el paisaje – al menos lo que yo vi entre Denver y Boulder – sí es bellísimo. <Smile type=":D" /></p>,
      },
    },
    {
      url: "ohio",
      title: {
        en: "Ohio",
        es: "Ohio",
        ru: "",
        pt: "Ohio",
        sw: "Ohio",
      },
      description: {
// ’ “” – …
        en: <p>I was on a drive with a fellow camper from InterNations (I’d flown to New Jersey to meet him) <ReadMore lang="en"> to go to Mammoth Cave. We were going to pass through Columbus, Ohio. Now I’d already hit Cincinnati and Cleveland, but I kept skirting the edges of this state and missing the capital city. <Smile type=":(" /> I explained my quirk and asked him if he wouldn’t mind driving me in to snap a photo of the building. And… he didn’t. <Smile type=":D" /></ReadMore></p>,
      },
    },
    {
      url: "minnesota",
      title: {
        en: "Minnesota",
        es: "Minnesota",
        ru: "",
        pt: "Minnesota",
        sw: "Minnesota",
      },
      description: {
        en: <p>I actually got to take a tour of this one! <Smile type=":o" /><Smile type=":p" /></p>,
      },
    },
  ],
};
