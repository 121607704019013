import React from 'react';
import Smile from '../Smile';
import ChLang from '../ChLang';

export const meru = {
  title: {
    en: "Karibu Kenya",
    sw: "Karibu Kenya",
  },
  subtitle: {
    en: "(That’s “Welcome to Kenya” in Swahili)",
    sw: <span>Sijakuja kufanya “safari” <Smile type=":p" /></span>,
  },
  url: "meru",
  img: "meru",
  country: "Kenya",
  city: "Meru",
  post: {
// ’ “” – …
    en: (funcs) => <div id="main-post-text">
      <p>A bit of backstory here: I’m writing this from a rural rouse near a place you’ve <i>probably</i> never heard of (Meru, Kenya). I’m Couchsurfing here. I landed in Nairobi, walked past all the “Karibu Kenya” signs (which also said “Welcome to Kenya”), past all the hotel people holding signs up with foreigners’ names, past all the taxis, and two hours along dark and deserted roads to meet my Couchsurfibg host, who’d tried to meet me at the airport but didn’t know the city (or how to use Google Maps) and, according to the location he sent me, was over two kilometres from the airport “pick-up” zone. It was easier to try to go meet him than explain how to get him to meet me. <Smile type=";)" /></p>
      <p>I’m going to try to write most of my Kenya posts in <ChLang lang="sw" f={funcs.chlang}>Swahili</ChLang>. Yes, that’s no joke – I’m learning Swahili and I think that, with some help from the great people I’m Couchsurfing with here, I can actually write in it. But I’m going to have English versions too (completely different, of course) – and that’s where I’m going to share an aspect of my travels that has little to do with Kenya and very much to do with English being my native language: the fact that I’m a muzungu (White person).</p>
      <p>I’m also a woman. A lone female traveller. Maybe surprisingly, that has far less effect on my life here in Meru than my skin colour does.</p>
      <p>I came into the house – or was shuttled in, in the middle of the night – and that’s when I met the whole family I was going to be living with for a week. That’s when we took a bunch of pictures – including the one by the title. <Smile type=":p" /> If you want to read more about the great times I’ve had here, read the <ChLang lang="sw" f={funcs.chlang}>Swahili version</ChLang>.</p>
      <p>Because this version is, sadly, going to take a negative turn… <Smile type=":(" /></p>
      <p>So what happened? After having a great time observing (and living!) life at the house, <Smile type=":)" /> I was surprised to not be invited to an event (a burial for a recently passed family member) that the whole family was attending. <Smile type=":/" /> After all, they’d welcomed me into everything else with open arms. I thought that:</p>
      <ol>
        <li>Maybe they didn’t want me intruding on their family affairs. Unlikely, because all of the neighbours seemed to be invited. But I was a guest; it wasn’t my place to insist.</li>
        <li>Or maybe it was because I was White.</li>
      </ol>
      <p>Several things wre cited by various people, including COVID restrictions and the neighbours practising witchcraft. I doubted that it was really just this, but I didn’t want to say anything.</p>
      <p>And here’s the craziest thing – I wanted it to be anything else. I <i>desperately</i> wanted it to be something besides the colour of my skin. I had the greedy desire to be able to fit in – to be able to live with this Kenyan family as one of them,  albeit not speaking their local language (kiMeru)  at all and very little Swahili. <Smile type=":/" /></p>
      <p>Over the next several days, I asked to get out and walk around. To visit nearby villages or markets. Nope, that wasn’t permissible… I had to stay inside. “<i>Kaa hapa</i> – Stay here.” My suspicion was that they hadn’t told anyone that there was a White person living here, and they didn’t want anyone finding out. This was reinforced when, the one time they had company, they told me to go into the house and literally locked the door to keep me in. <Smile type=":k" /></p>
      <p>Two days after the burial, it came out (in English; the man who said this would help me practise Swahili most times, but he used Eglish with serious matters): “You know, people here, they do not understand. They think that if you are a muzungu, you have money. Maybe they will think there is money in this house. Maybe they will come at night and attack us, to try to get the money that they think you must have.” In short, my presence was endangering them. <Smile type=":o" /></p>
      <p></p>
      <p>Now I understand “White privilege” a lot better – because, coming to Africa, that’s what I lost. Now I have to be aware, 24/7, of the colour of my skin. I know that I stand out. I know that I attract unwanted attention. I’m a liability. My very existence here threatens the safety of the people who are housing and feeding me.</p>
      <p>And here’s the thing: I’m sure it would’ve been different if I’d been doing what White people were <i>supposed</i> to do in Africa – which is go “glamping” and spend lots of money, or work on some charity project or NGO surrounded by smiling African children. I was stepping out of my role. I was stepping across class lines – and here, in post-colonial Africa, class is so closely tied to race that the two are inseperable.</p>
      <p>And now think of where you live – which, since you’re reading this in English, is probably the UK or USA or Australia. Think how it would be if a Black person tried to step out of class lines. Think if a Black person were the top dog, or the CEO. Whether you’re for that or against it, there’s no doubt that the first thing on many people’s minds would be the colour of that person’s skin.</p>
      <p>No one talks about Joe Biden being White. But everyone talks about Kamala Harris being a “person of colour”. If you’re White, think about that for a while.</p>
    </div>,
    sw: <div id="main-post-text">
      <p>Maneno ya kwanza ya Kiswahili niliyoona yalikuwa uwanjani wa ndege, baada ya kutoka ndege. Yalikuwa “Karibu Kenya”.</p>
      <p>Tayari nilijua waKenya wengi – angalau watu wanne. <Smile type=":)" /> Niliwatana toka tovuti za Couchsurfing na InterNations. <Smile type=":p" /></p>
      <p>Ndio – mimi ni muzngu. Ngozi yangu ni nyeupe sana. <Smile type=":o" /> Lakini, mimi sio kama wazungu wengi. Sitaki kuangalia wanyama wa Afrika. Nataka kutana watu wa Afrika <Smile type="8)" /></p>
      <p>Kitu cha kwanza nilichofanya kilikuwa kumtana mtu niliyemjua toka Couchsurfing, na kusafiri naye toka mji mkuu kwende kijiji kidogo sana – kuishi maisha ya waKenya <Smile type=";)" /></p>
      <p>Naandika hii toka nyumba yao – baada ya mwanaume hapa amenitoa chakula na chai ntamu sana. <Smile type=":D" /> Hapa naweza kujaribu kuisha kama mKenya. <Smile type=":p" /></p>
      <p>Wamenifundish vitu vyingi. Msichana hapa amenifundisha kufua nguo na mikono. <Smile type=":p" /> Familia imenifundisha kipika na moto. <Smile type=":p" /> Wamenifundisha kupika ugali, na chai, na chapati. <Smile type="8)" /></p>
      <p>Matata ya kipekee nilikuwa yalitoka pesa. <Smile type=":(" /> Hakuna pesa nyingi. Mimi sina pesa nyingi, lakini lazima wanafikiri nina marafiki matajiri katika Marekani. Najaribu kusema kwamba sina <i>connections</i> hizi, na sijui bado nikirudi Marekani, ama kuishi katiki mahali pengine.</p>
      <p>Bado sijui nitaishije. <Smile type=":/" /></p>
    </div>,
  },
};
