import React, {Component} from 'react';
import './LangPicker.css';

const pick_lang = {
  en: "Pick a language:",
  es: "Elige un idioma:",
  ru: "Выберите язык:",
  pt: "Escolha uma língua:",
  sw: "Chagua lugha:",
  tr: "Bir dil seç:",
  el: "Επιλέξτε μια γλώσσα",
  is: "Veldu tungumál",
};
const langs = {
  en: "English",
  es: "Español",
  ru: "Русский язык",
  pt: "Português",
  sw: "Kiswahili",
  tr: "Türkçe",
  el: "Ελληνικά",
  is: "Íslenska",
};

class LangPicker extends Component {
  render() {
    const {lang, chlang} = this.props;
    const options = Object.keys(langs).map((key) => {
      if(!langs.hasOwnProperty(key)) {return null;}
      if (this.props.limit && !this.props.limit[key]) {return null;}
      const selected = key === lang;
      return <option
        key={key}
        value={key}
        selected={selected}
      >
        {langs[key]}
      </option>;
    });
    return <div id="lang-picker-div">
      <h1>{pick_lang[lang]}</h1>
      <select id="lang-picker" onChange={(e)=>chlang(e.target.value)} style={{backgroundImage: "images/langpicker/" + lang + ".png"}}>
        {options}
      </select>
    </div>;
  }
}

export default LangPicker;
