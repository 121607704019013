import React, {Component} from 'react';
import './ReadMore.css';

const read_less_text = {
  en: "Read less",
  es: "Leer menos",
  ru: "Прочитать меньше",
  pt: "Ler menos",
  sw: "Pungua maandishi",
};

class ReadMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  render() {
    const {collapsed} = this.state;
    const {lang, children, alwaysHide} = this.props;
    const className = (alwaysHide) ?
      "read-more alwayshide lang-" + lang : "read-more lang-" + lang;
    var html = [];
    html.push(
      <span
        className={(collapsed) ? className : ""}
        onClick={()=>this.setState({collapsed: false})}
      >
        {children}
      </span>
    );
    if (!collapsed && alwaysHide) {
      html.push(<div
      className={"read-less " + "lang-" + lang}
      onClick={()=>this.setState({collapsed: true})}
    >
      [{read_less_text[lang]}]
    </div>);
    }
    return html;
  }
}

export default ReadMore;
