import React, {Component} from 'react';
import Smile from './Smile';
import "./Publications.css";
import {pubs, window_description} from './pubs-list';

const genre = {
  en: "Genre",
  es: "Género",
  ru: "Жанр",
  pt: "Gênero",
  sw: "Aina",
};
const magazine = {
  en: "Magazine",
  es: "Revista",
  ru: "Журнал",
  pt: "Revista",
  sw: "Jarida",
};
const language = {
  en: "Language",
  es: "Idioma",
  ru: "Язык",
  pt: "Língua",
  sw: "Lugha",
};

function Pub(props) {
  const {lang, chlang, pub, side} = props;
  const image = <div
    className="pub-image-container"
    style={{float: side}}
  >
    <a
      href={pub.url}
      target="_blank"
      rel="noopener noreferrer"
    >
       <img
        className="pub-image"
        src={pub.image}
        alt={pub.title}
      />
    </a>
  </div>;
  const title = <h3 className="pub-title">
    <a href={pub.url} target="_blank" rel="noopener noreferrer">
      {pub.title}
    </a>
  </h3>;
  const description = <div className="pub-description">
    {(typeof pub.description[lang] === 'function') ?
      pub.description[lang]({chlang: chlang}) : pub.description[lang]}
  </div>
  const info = <div className="pub-info-container">
    <div className="pub-info" style={{width: "100%", display: "block", borderBottom: "black 1px dashed", marginBottom: "3px"}}>
      {language[lang]}: {pub.language[lang]}
      </div>
    <div className="pub-info">
      {genre[lang]}: {pub.genre[lang]}
    </div>
    <div className="pub-info">
      {magazine[lang]}: <a
        href={pub.mag_site}
        target="_blank"
        rel="noopener noreferrer"
      >
        {pub.mag}
      </a>
    </div>
  </div>;
  const body = <div className="pub-body">
    {title}
    {description}
  </div>;
  return <div className="pub" key={pub.title} style={{textAlign: side}}>
    {image}
    {body}
    <div style={{clear: "both"}} />
    {info}
  </div>;
  }

class Publications extends Component {
  render() {
    const {lang, chlang} = this.props;
    let pubs_list = [];
    let side = "right";
    for (let i=0;i<pubs.length;i++) {
      if (!pubs[i].description[lang]) {continue;}
      pubs_list.push(<Pub
        lang={lang}
        chlang={chlang}
        pub={pubs[i]}
        side={side}
      />);
      side = (side === "left") ? "right" : "left";
    }
    const window_desc = (typeof window_description[lang] === 'function') ?
      window_description[lang]({chlang: this.props.chlang}) :
      window_description[lang];
    return (
      <div id="publications">
        {window_desc}
        {pubs_list}
      </div>
    );
  }
}

export default Publications;
