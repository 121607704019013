import React from 'react';
//import {disp_num} from './Ingredient';

function copyKeyNamesToData(obj) {
  var objCopy = {};
  Object.keys(obj).map((key) => {
    objCopy[key] = {...obj[key]};
/*    if (!objCopy[key].name) {
      objCopy[key].name = key;
    }*/
    if (!objCopy[key].image) {
      objCopy[key].image = key + ".jpg";
    }
    return true;
  });
  return objCopy;
}

export const measures = {
  cup: {
    name: {
      en: "cup",
      es: "taza",
      ru: "стакан",
      pt: "xícara",
    },
    plural: {
      en: "cups",
      es: "tazas",
      ru: "стакана",
      ru_mult: "стаканов",
      pt: "xícaras",
    },
  },
  tbsp: {
    name: {
      en: "tbsp",
      es: "cucharada",
      ru: "столовая ложка",
      pt: "colher de sopa",
    },
    plural: {
      en: "tbsps",
      es: "cucharadas",
      ru: "чайные ложки",
      ru_mult: "столовых ложек",
      pt: "colheres de sopa",
    },
  },
  tsp: {
    name: {
      en: "tsp",
      es: "cucharaditas",
      ru: "чайная ложка",
      pt: "colher de chá",
    },
    plural: {
      en: "tbsps",
      es: "cucharaditas",
      ru: "чайная ложка",
      ru_mult: "чайных ложек",
      pt: "colheres de chá",
    },
  },
  clove: {
    name: {
      en: "clove",
      es: "diente",
      ru: "зубчик",
      pt: "dente",
    },
    plural: {
      en: "cloves",
      es: "dientes",
      ru: "зубчика",
      ru_mult: "зубчиков",
      pt: "dentes",
    },
  },
};

export const ingredients = copyKeyNamesToData({
  chickpeas: {
    name: {
      en: "chickpeas",
      es: "garbanzos",
      ru: "нуты",
      pt: "grão de bico",
    },
    kcal: 230, // 1 cup wet
  },
  cuminPowder: {
    name: {
      en: "ground cumin",
      es: "comino molido",
      ru: "молотый тмин",
      pt: "cominho em pó",
    },
    kcal: 11, // 1 tsp
  },
  garlic: {
    name: {
      en: "garlic",
      es: "de ajo",
      ru: "чеснока ",
      pt: "de alho",
    },
    measure: measures.clove,
    kcal: 4, // 1 clove
  },
  lemonJuice: {
    name: {
      en: "lemon juice",
      es: "jugo de limón",
      ru: "лимонный сок",
      pt: "suco de limão",
    },
    kcal: 1, // 1 tsp
  },
  oliveOil: {
    name: {
      en: "extra virgen olive oil",
      es: "aceite de oliva virgen extra",
      ru: "оливковое масло первого отжима",
      pt: "azeite de oliva extra virgem",
    },
    kcal: 40, // 1 tsp
  },
  salt: {
    name: {
      en: "salt",
      es: "sal",
      ru: "соль",
      pt: "sal",
    },
    kcal: 0,
  },
  tahini: {
    name: {
      en: "tahini",
      es: "tahini",
      ru: "тахини",
      pt: "tahini",
    },
    kcal: 30, // 1 tsp
  },


/*  salt: {
    name: {
      en: "salt",
      es: "sal",
      ru: "соль",
      pt: "sal",
    },
    kcal: 0
  },
  pepper: {
    name: {
      en: "pepper",
      es: "pimiento negro",
      ru: "перец",
      pt: "pimenta preta",
    },
    kcal: 5, // 1 tsp
  },
  freshHerbs: {
    name: {
      en: "fresh herbs",
      es: "hierbas frescas",
      ru: "свежие травы",
      pt: "ervas frescas",
    },
    kcal: 0,
  },
  coriander: {
    name: {
      en: "coriander",
      es: "cilantro",
      ru: "кориандр",
      pt: "coentro",
    },
    kcal: 0,
  },
  oregano: {
    name: {
      en: "oregano",
      es: "orégano",
      ru: "орегано",
      pt: "orégano",
    },
    kcal: 0,
  },
  groundGinger: {
    name: {
      en: "ground ginger",
      es: "genjibre molido",
      ru: "молотый имбирь",
      pt: "gengibre em pó",
    },
    kcal: 6, // 1 tsp
  },
  cuminSeeds: {
    name: {
      en: "cumin seeds",
      es: "semillas de comino",
      ru: "семена тмина",
      pt: "sementes de cominho",
    },
    kcal: 8, // 1 tsp
  },
  sweetener: {
    name: {
      en: "sweetener",
      es: "endulzante",
      ru: "подсластитель",
      pt: "adoçante",
    },
    kcal_min: 0, kcal_max: 50, // 1 tbsp
  },
  sugar: {
    name: {
      en: "sugar",
      es: "azúcar",
      ru: "сахар",
      pt: "açúcar",
    },
    kcal: 45, // 1 tbsp
  },
  mapleSyrup: {
    name: {
      en: "maple syrup",
      es: "",
      ru: "",
      pt: "",
    },
    kcal: 50, // 1 tbsp
  },
  cinnamon: {
    name: {
      en: "cinnamon",
      es: "canela",
      ru: "корица",
      pt: "canela",
    },
    kcal: 5, // 1 tsp
  },
  oliveOil: {
    name: {
      en: "extra virgen olive oil",
      es: "aceite de oliva extra virgen",
      ru: "",
      pt: "",
    },
    kcal: 120, // 1 tbsp
  },
  cacaoButter: {
    name: {
      en: "cacao butter",
      es: "manteca de cacao",
      ru: "какаоское масло",
      pt: "manteiga de cacau",
    },
    kcal: 840, // 100 grams
    guide: {
      en: (scale) => "If you don’t have this, try substituting coconut oil.",
      es: (scale) => "Si no tienes esto, usa aceite de coco.",
      ru: (scale) => "Если у вас нет это, использовуйте кокосовое масло",
      pt: (scale) => "Se você não tem isso, use óleo de coco",
    },
  },
  garlic: {
    name: {
      en: "",
      es: "",
      ru: "",
      pt: "",
    },
    kcal: 5, // 1 clove
  },
  onion: {
    name: {
      en: "",
      es: "",
      ru: "",
      pt: "",
    },
    plural: {
      en: "",
      es: "",
      ru: "",
      pt: "",
    },
    kcal: 50
  },
  lemonJuice: {
    name: {
      en: "",
      es: "",
      ru: "",
      pt: "",
    },
    kcal: 0
  },
  water: {
    name: {
      en: "water",
      es: "agua",
      ru: "вода",
      pt: "água",
    },
    kcal: 0
  },
*/
/*  olive: {plural: "olives", image: "olives.jpg", kcal: 150}, // 1 cup black
  tomato: {plural: "tomatoes", kcal: 25},
  tomatoPaste: {name: "tomato paste", kcal: 20}, // 1 tbsp
  veganYoghurt: {name: "vegan yoghurt", kcal_min: 70, kcal_max: 140}, // 1 cup
  nutMilk: {name: "nut “milk”", kcal_min: 25, kcal_max: 140}, // 1 cup
  soyMilk: {name: "soy “milk”", kcal_min: 70, kcal_max: 100}, // 1 cup
  oatMilk: {name: "oat “milk”", kcal_min: 60, kcal_max: 120}, // 1 cup
  coconutMilk: {name: "coconut “milk”", kcal: 45}, // 1 cup
  nuts: {kcal_min: 680, kcal_max: 800}, // 1 cup
  pasta: {kcal: 280}, // 1 cup dry
  soySpaghetti: {name: "soy spaghetti", kcal: 280}, // 80g
  lentils: {kcal: 480}, // 1 cup dry
  oatmeal: {kcal: 300}, // 1 cup dry
  wheatFlour: {name: "whole wheat flour", kcal: 400}, // 1 cup
  buckwheatFlour: {name: "buckwheat flour", kcal: 400}, // 1 cup
  speltFlour: {name: "spelt flour", kcal: 520}, // 1 cup
  bakingSoda: {name: "baking soda", kcal: 0},
  bakingPowder: {name: "baking powder", kcal: 0, guide: (scale) => <p>NOT baking <i>soda</i>!</p>},
  chocolate: {kcal: 600}, // 100g
  banana: {plural: "bananas", kcal_min: 66, kcal_max: 150},
  tropicalFruits: {name: "tropical fruits", kcal_min: 50, kcal_max: 130}, // 1 cup
  blueberry: {plural: "blueberries", image: "blueberries.jpg", kcal: 83}, // 1 cup
  walnut: {plural: "walnuts", image: "walnuts.jpg", kcal: 800}, // 1 cup
  hempSeeds: {name: "hemp seeds", plural: "hemp seeds", kcal: 50}, // 1 tbsp
  curryPowder: {name: "curry powder", kcal: 6}, // 1 tsp
  garamMasala: {name: "garam masala", kcal: 10, measure: "tsp"}, // 1 tsp
  carrot: {plural: "carrots", image: "carrots.jpg", kcal: 30}, // 1 large
  potato: {plural: "potatoes", image: "potatoes.jpg", kcal: 160}, // 1 medium
  rootVegetables: {
    name: "root vegetables",
    kcal_min: 50, // 1 cup carrots
    kcal_max: 150, // 1 cup potatoes
  },
  leafyGreens: {name: "leafy greens", kcal: 50},
  bokChoy: {name: "bok choy", kcal: 10}, // 100g
  cabbage: {modifier: "large", measure: "head", kcal: 300}, // 1 lg. head
  cauliflower: {modifier: "large", measure: "head", kcal: 150}, // 1 lg. head
  broccoli: {measure: "head", kcal: 200}, // 1 head
  cucumber: {plural: "cucumbers", kcal: 30}, // 1 medium
//  cucumber: {plural: "cucumbers", kcal: 30}, // 1 medium
  aubergine: {plural: "aubergines", kcal: 40}, // 1 medium
*/
});
