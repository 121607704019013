import React, {Component} from 'react';
import Smile from './Smile';
import './Photography.css';
import {
  window_description,
  initial_photos,
  initial_desc,
  initial_folder_name
} from './photos';

function convert_title_to_url(title) {
  var url = "";
  for (let i=0;i<title.length;i++) {
    let letter = title.charAt(i);
    if (letter.match(/[a-z0-9]/)) {
      url += letter;
    }
    else if (letter.match(/[A-Z]/)) {
      url += letter.toLowerCase();
    }
    else if (letter === " " || letter === "\u00A0") {
      url += "-";
    }
    else if (letter === "Á" || letter === "á" ||
             letter === "ã" || letter === "à" || letter === "À") {
      url += "a";
    }
    else if (letter === "É" || letter === "é" || letter === "ê") {
      url += "e";
    }
    else if (letter === "Í" || letter === "í") {
      url += "i";
    }
    else if (letter === "Ó" || letter === "ó" ||
             letter === "õ" || letter === "ô") {
      url += "o";
    }
    else if (letter === "Ú" || letter === "ú" || letter === "ü") {
      url += "u";
    }
    else if (letter === "ñ") {
      url += "n";
    }
  }
  return url;
}

function cleanup_photos(photos) {
  for (let i=0;i<photos.length;i++) {
    // If no extension is given, use .jpg
    if (!photos[i].ext) {
      photos[i].ext = "jpg";
    }
    // If no URL is given, use the title.
    if (!photos[i].url) {
      // Try the languages in this order ;)
      var title = photos[i].title.en;
      if (!title) {title = photos[i].title.es;}
      // it won't work with russian letters
      if (!title) {title = photos[i].title.pt;}
      if (!title) {title = photos[i].title.sw;}
      photos[i].url = convert_title_to_url(title);
    }
  }
  return photos;
}


class Photography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder: [],
      folder_name: [initial_folder_name],
      cur_photos: [cleanup_photos(initial_photos)],
      desc: [initial_desc],
      preview: null,
    };
  }
  lookAt(image) {
    const folder_url = this.state.folder.join("/");
    if (!image) {
      this.setState({
        preview: image
      });
      this.props.setUrl(folder_url);
      return;
    }
    const image_url = (folder_url) ? folder_url + "/" + image.url : image.url;
    this.props.setUrl(image_url);
    // If it's a "folder", just open that :)
    if (image.folder) {
      this.openFolder(image);
      return;
    }
/* shouldn't be necessary
    if (image && !image.url) {
      image.url = convert_title_to_url(image.title);
    }*/
    this.setState({
      preview: image
    });
  }
  stopLooking() {
    this.lookAt(null);
  }
  openFolder(f) {
    var {folder, folder_name, desc, cur_photos} = this.state;
    folder.push(f.url);
    folder_name.push(f.title);
    desc.push(f.description);
    cur_photos.push(cleanup_photos(f.photos));
    this.setState({
      folder: folder,
      folder_name: folder_name,
      desc: desc,
      cur_photos: cur_photos,
    });
  }
  upAFolder() {
    var {folder, folder_name, desc, cur_photos} = this.state;
    if (!folder.length) {return;}
    folder.pop();
    folder_name.pop();
    desc.pop();
    cur_photos.pop();
    this.props.setUrl(folder.join("/"));
    this.setState({
      folder: folder,
      folder_name: folder_name,
      desc: desc,
      cur_photos: cur_photos,
      preview: null,
    });
  }
  componentDidMount() {
    var {path} = this.props;
    if (!path || !path.length) {return;}
    // First try to enter any folder it's in...
    var start_setup = {
      folder: [],
      folder_name: [initial_folder_name],
      cur_photos: [cleanup_photos(initial_photos)],
      desc: [initial_desc],
    };
    while (path.length) {
      var next_in_path = path.shift();
      const cur_photos = 
        start_setup.cur_photos[start_setup.cur_photos.length-1];
      var i;
      for (i=0;i<cur_photos.length;i++) {
        if (cur_photos[i].url === next_in_path) {break;}
      }
      if (i < cur_photos.length) {
        const photo = cur_photos[i];
        if (photo.folder) {
          start_setup.folder.push(photo.url);
          start_setup.folder_name.push(photo.title);
          start_setup.desc.push(photo.description);
          const next_photos = cleanup_photos(photo.photos);
          start_setup.cur_photos.push(next_photos);
        }
        else {start_setup.preview = photo;}
      }
    }
    this.setState(start_setup);
  }
  render() {
    var {preview, folder, folder_name, desc, cur_photos} = this.state;
    const folder_url = (folder.length) ? folder.join("/") + "/" : "";
    // Just look at the last element of everything ;)
    // There doesn't appear to be a more elegant way to do this :(
    folder_name = folder_name[folder_name.length-1];
    desc = desc[desc.length-1];
    cur_photos = cur_photos[cur_photos.length-1];
    const {lang} = this.props;
    const preview_image = (preview) ? 
      <div
        id="preview-image-container"
        onClick={(e) => {
          console.log(e.target);
          if (
            (e.target.tagName !== "IMG" && e.target.tagName !== "DIV")
            || e.target.className === "smile"
          ) {return;}
          this.stopLooking();
        }}
      >
        <div id="preview-image-centred-container">
          <img
            id="preview-image"
            src={"/images/photography/" + folder_url + 
              preview.url + "." + preview.ext}
            alt={preview.title}
          />
          <div id="preview-title-container">
            <h1 id="preview-title">{preview.title[lang]}</h1>
          </div>
          {preview.description && preview.description[lang] &&
          <div id="preview-description">
            {preview.description[lang]}
          </div>}
        </div>
      </div> : 
      null;
    const photos_display = cur_photos.map(
      (photo) => {
        if (!photo || !photo.title || !photo.title[lang]) {return null;}
        const div_className = (photo.folder) ?
          "photo-tiny-div folder" : "photo-tiny-div image";
        // photo.titleText is for if the title has HTML ;)
        const photoTitle = (photo.titleText && photo.titleText[lang]) ?
          photo.titleText[lang] : photo.title[lang];
        return <div
          key={photoTitle}
          className={div_className}
          onClick={() => this.lookAt(photo)}
        >
          <img
            className="photo-tiny"
            src={"/images/photography/" + folder_url + photo.url +
              "-tiny." + photo.ext}
            alt={photoTitle + " (thumbnail)"}
            title={photoTitle}
          />
          {photo.folder && <img
            src="/images/photography/folder.png"
            className="photo-tiny-folder"
          />}
        </div>;
      }
    );
    const up_folder_button = (folder.length) ? <div
      id="up-folder-button"
      onClick={this.upAFolder.bind(this)}
      >
      <img src="/images/photography/up-folder.png" alt="Up a folder" />
    </div> : null;
    const window_desc = (typeof window_description[lang] === 'function') ?
      window_description[lang]({chlang: this.props.chlang}) :
      window_description[lang];
    const desc2 = (typeof desc[lang] === 'function') ?
      desc[lang]({chlang: this.props.chlang}) :
      desc[lang];
    return (
      <div id="photos">
        {preview_image}
        {window_desc}
        {up_folder_button}
        <h2 id="folder-name">{folder_name[lang]}</h2>
        {desc2}
        <div id="photo-div">
          {photos_display}
        </div>
      </div>
    );
  }
}

export default Photography;
