import React from 'react';
import Smile from './Smile';
import ReadMore from './ReadMore';
import ChLang from './ChLang';

import {india} from './photos/india';
import {train} from './photos/train';
import {brazil} from './photos/brazil';
import {costarica} from './photos/costarica';
import {capitols} from './photos/capitols';
import {kenya} from './photos/kenya';

export const window_description = {
// ’ “” –
  en: <div className="window-description">
    <p>So, I take photographs. Lots of photographs. <Smile type=":o" /> Mostly it’s a hobby. I had a brief, utterly failed business venture where I tried selling them through Etsy, but other than that… I just make these because I like making them. <Smile type=":)" /></p>
    <p>With some of these I’m including a caption, about where or why I took them. With others, the photo will have to suffice. <Smile type=";)" /></p>
    <p>Enjoy!</p>
  </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
  es: <div className="window-description">
    <p>Para mi, tomar fotos es un pasatiempo con que malgasto bastante tiempo <Smile type=":o" /> Quizás, un día, yo podré ganar dinero así. Sin embargo, por el momento yo sólo tengo un primero emprendimiento fracasado, «Pictures From Around the Globe» (Fotos de todo el globo), que ahora ni siquiera ha retenido su propia página web <Smile type=":/" /></p>
    <p>Pero yo sigo tomando fotos. ¿Con ellas, qué voy a hacer? No sé. Pero las tengo.</p>
    <p>¿Por qué no mostrarlas aquí <Smile type=";)" />?</p>
  </div>,

// ё — «» …
  ru: <div className="window-description">
    <p>Я тоже фотограф <Smile type=";)" /> Я фотографирую со всего мира <Smile type="8)" /></p>
    <p>Я хочу, чтобы они вам понравились! <Smile type=":o" /></p>
  </div>,

// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
  pt: <div className="window-description">
    <p>Além de tudo, eu também sou fotógrafa. Eu tirei fotos de todo o mundo. É, principalmente, um passatempo, mas eu, sim, vendi umas poucas <Smile type=":)" /> (Embora elas fossam vendidas tudos à minha família e aos meus amigos <Smile type=";)" />) </p>
    <p>Aqui eu tenho umas das minhas favoritas <Smile type=":p" /> Espero que você goste delas <Smile type=":D" /></p>
  </div>,

// “” – …
  sw: <div className="window-description">
    <p>Ninapenda sana kupiga picha. Nipo Kenya sasa. Lakini sio kama wazungu wengi. Sifanyi “safair”. Mpaka sasa, nimefika nyumba ya rafiki wangu na siyawezi kutoka. Sina picha mpya nyingi, lakini nina wakati kufanya kazi na tovuti yangp <Smile type=":p" /></p>
  </div>,
};

export const initial_folder_name = {
  en: "Jane’s pics",
  es: "Las fotos de Juana",
  ru: "Фотографии Иванны",
  pt: "A fotografia de Joana",
  sw: "Picha za Yohana",
};


export const initial_desc = {
// ’ “” –
  en: (funcs) => <div className="folder-description">
    <p>I have a lot of these. But I’m trying to learn a lot of languages too <Smile type=":)" /> So, if you want to see more of my pictures, try changing the language to <ChLang lang="es" f={funcs.chlang}>Spanish</ChLang>, or even <ChLang lang="sw" f={funcs.chlang}>Swahili</ChLang>! Use Google Translate if you have to. <Smile type=":p" /> (I do. Often. <Smile type=";)" />) But have fun, and explore the site! <Smile type=":D" /></p>
  </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
  es: (funcs) => <div className="folder-description">
    <p>Claro, éstas no son todas mis fotos. Pues, ¿dónde están? ¡Los demás sólo están disponibles en <ChLang lang="en" f={funcs.chlang}>otros</ChLang> <ChLang lang="pt" f={funcs.chlang}>idiomas</ChLang> <Smile type=":p" />!</p>
  </div>,

// ё — «» …
  ru: (funcs) => <div className="folder-description">
    <p>Если ты хочешь увидеть больше моих фотографий, <ChLang lang="es" f={funcs.chlang}>измени</ChLang> <ChLang lang="pt" f={funcs.chlang}>язык</ChLang>!</p>
  </div>,

// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
  pt: (funcs) => <div className="folder-description">
    <p>Você pode ver as minhas fotos cá. Quer mais? <ChLang lang="en" f={funcs.chlang}>Troca</ChLang> <ChLang lang="ru" f={funcs.chlang}>a</ChLang> <ChLang lang="es" f={funcs.chlang}>língua</ChLang>! <Smile type=";)" /></p>
  </div>,

// “” – …
  sw: (funcs) => <div className="folder-description">
    <p>Sina picha nyingi sasa. <Smile type=":(" /> Natumai kupiga picha nyingi hivi karibuni <Smile type=":)" /></p>
    <p>Sasa picha zingine zinapatikana za <ChLang lang="ru" f={funcs.chlang}>lugha</ChLang> <ChLang lang="pt" f={funcs.chlang}>zingine</ChLang>. <Smile type=";)" /></p>
  </div>,
};

// ’ “” – …
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
// ё — «» …
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …

// This is the full list of photos... and all sub-folders ;)
// In the ORDER OF WHEN THEY WERE TAKEN (except the capitols)
export var initial_photos = [
  capitols,
  india,
  train,
  {
    url: "national-guard",
    title: {
      en: "We\u00A0Are Guarded",
      es: "Nosotros\u00A0somos guardados",
      ru: "Военные\u00A0нас защищают",
      pt: "A\u00A0gente\u00A0está guardada…",
      sw: "Tuna salama?",
    },
    description: {
      en: <p>Taken days after riots erupted across the USA over the death of George Floyd. The answer to police brutality? <i>MORE POLICE</i> <Smile type=":p" /></p>,
      ru: <p>Может быть, это—иронично.</p>,
      pt: <p style={{width: "100%", textAlign: "center"}}><Smile type=":/" /></p>,
      sw: <p>Hapa, katika Marekani, "White privilege" inamaanisha kwamba mimi, msichana muzungu, naweza kukaa karibu na polisi na hakuna matata. <ReadMore lang="sw">Mtu Mwafrica-Mmarekani hanaweza kuifanya hii. <Smile type=":k" /></ReadMore></p>,
    }
  },
  {
    url: "oh-my",
    title: {
      en: "Oh\u00A0My!",
      sw: "Imefanya nini?"
    },
    description: {
      en: <p>Who doesn’t love an ADORABLE chipmunk?</p>,
    },
  },
  {
    url: "is-anarchy-the-answer",
    title: {
      en: "Is\u00A0Anarchy the\u00A0Answer?",
      ru: "Анархия. Это—ответ?",
    },
  },
  brazil,
  costarica,
  kenya,
];

