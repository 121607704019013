import React from 'react';
import Smile from '../Smile';
// import ChLang from '../ChLang';

export const internations = {
  title: {
    sw: "Rafiki yangu ya InterNations",
  },
  subtitle: {
    sw: "Nilijifunza naweza kuzungumza kwa kiSwahili!",
  },
  url: "internations",
  img: "rafiki-ya-internations",
  country: "Kenya",
  city: "Nairobi",
  post: {
// ’ “” – …
    sw: <div id="main-post-text">
      <p>Nimesafiri nchini Kenya na msaada wa Couchsurfing, kukaa na watu waKenya wengi bila pesa. Nikafika Nairobi saa tisa asubuhi na nikaende kabla ya saa moja kuishi na familia katika Meru. <Smile type="8)" /> Sijakaa hotelini, na sijajua Nairobi. <Smile type=":o" /></p>
      <p>Lakini, ningalimtana mtu mmoja hanatokayo Couchsurfing. Nilimjua na site tofauti: InterNations.</p>
      <p>Nilijua kwamba watu wa Internations hawanatanafana watu wa Couchsurfing. Nimewatana katika Marekani, na nimepiga kampi na wao, lakini nilienda kwa sababu wao walikuwa watu wengine kuliko mimi. <Smile type=":(" /> (Nitaandika post juu ya hali hii baadaye, kwa kiIngereza.)</p>
      <p>Tatizo moja ni watu wa InterNations wana pesa. Wanapenda kuwa watalii. Mimi, hapana. <Smile type=":o" /> Sina pesa. Lakini, mtu nitayotana amewapa watu wa InterNations wote uwasilishaji ya mtandao juu ya vitu vyote watalii wanaweza kufanya katika Kenya. <Smile type=":/" /> Niliogopa angalifikiri mimi (kama muzungu) amekuje hapa kuwa mtalii tu. <Smile type=":k" /></p>
      <p>Lakini, amenipoongea katika WhatsApp na Zoom, aliandika kiSwahili tu; alijua nilijifunza lugha. Alinisaidia kusoma. KiSwahili chake mara nyingi ndicho kilikuwa kigumu sana, na nilitumia Google mara yote!</p>
      <p>Nilifikiri namseme kiSwahili changu si kizuri sana, na ama labda angalisema polepole, au labda angalizungumza kwa kiIngereza (ambaku sikutaka <Smile type=":k" />). Alinipouliza kumtana katika jengo kubwa na fujo sana, sababu sikutaka kuwa kama mtalii, na sikusema kitu.</p>
      <p>Na tukatanana. Yeye alifanya kazi pale. Hana pesa. Aliishi katika “slum” (alivyosema) mbali toka jengo alimofanya kazi.</p>
      <p>Nimefurahi sana kujua maishi ya waKenya kwa ukweli! Alinionyesha mji, na nyumba yake. Majirani yake wakanipika chakula na chai. Wakaniuliza nirudie, na nikae na wao. Nikapanga kurudi na kufanya hivyo – halafu kuwatembelea familia ya rafiki yangu katika Mombasa! <Smile type=":D" /></p>
      <p>Kitu kikubwa kwa mimi kilikuwa kujifunza kwamba mimi naweza kuelewa kiSwahili. <Smile type=":o" /> Akajua najifunza na akasema polepole, lakini nikaweza kuelewa. Sikujui – lakini mimi kwa ukweli nasema kiSwahili! <Smile type=":p" /></p>
    </div>,
  },
};
