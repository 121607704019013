import React from 'react';
import Smile from './Smile';
import ReadMore from './ReadMore';
import ChLang from './ChLang';

import {welcome} from "./blog/welcome";
import {whatsapp} from "./blog/whatsapp";
import {dancing} from "./blog/dancing";
import {foodbank} from "./blog/foodbank";
import {meru} from "./blog/meru";
import {mfangano} from "./blog/mfangano";
import {internations} from "./blog/rafiki-ya-internations";
// Keep in reverse chronological order, with "welcome" doubled at the front.
export var blog = [
  welcome,
  mfangano,
  internations,
  meru,
  // whatsapp,
  foodbank,
  dancing,
  welcome,
];

export const window_description = {
// ’ “” – …
  en: <div className="window-description">
    <p>One of the main things that I do in my life is I travel. All over. Example: I’m not writing this from exactly what I’d call “home”. Sure, it’s somebody’s home. Just not mine. I met this person about three or four weeks ago, through a website called Couchsurfing. Before that, I was stying with a whole army of hosts whom I’d met through the internet, each taking a few days out of their life to show me their lives and surroundings.</p>
    <ReadMore lang="en" alwaysHide={true}>
      <p>Right now I’m staying in place to observe Ramadan (no eating from 5:00AM until about 8:00 in the evening) with some Muslim Couchsurfing friends. So I’m not really moving. This is my down time.</p>
      <p>And when I’m not having some “down time”? Then I go places where I’m told that no one should go – much less a woman travelling all on her own. I do things that I’m told that no one should do – much less a woman travelling all on her own. <Smile type=":o" /> And yet, I make it work.</p>
      <p>Hence the title. You’ve heard of the “vagina monologues”, right? This is similar – with a “Nomadland” twist. <Smile type=":p" /> This is the space for me, as a woman, to freely speak, and share the things that I do, my gender and other societal norms left behind me. I’m not recommending any of this to anyone else; God forbid! I’m just sharing my experience – as a woman – living a life that most peope of any gender would run away from screaming.</p>
      <p>That’s right… this just got <i>real</i> <Smile type=";)" /></p>
    </ReadMore>
  </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
  es: <div className="window-description">
    <p>Hola <Smile type=":)" /> Has descubierto mi blog de viajes. Viajo mucho, y pensé en compartir mis experiencias como una mujer viajera sin remedio. <Smile type=":o" /></p>
    <p>¿Y por qué no escribir unas entradas en el castellano? Al fin y al cabo, soy al menos bilingüe <Smile type=";)" /></p>
    <ReadMore lang="es" alwaysHide={true}>
      <p>Esto sí es un blog de viajes, pero es también más. Es una prueba de la capacidad del ser humano (o de ser humano), un testamento de la posibilidad que viene con existir. Aun siendo una mujer. <Smile type="8)" /></p>
      <p>No voy a repetir las entradas del blog en inglés, traduciéndolos en español… Voy a escribir mis entradas propias en este idioma. Pero lo que significa esto es que, ahorita… hay que esperar un poquito. Tengo menos razón de escribir una entrada aquí que no puedo compartir en el Good Men Project, donde espero alcanzar a más lectores. <Smile type=":p" /></p>
      <p>¡Paciencia!</p>
    </ReadMore>
  </div>,

// ё — «» …
  ru: undefined,

// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
  pt: undefined,

  sw: (funcs) => <div className="windoow-description">
    <p>Jambo!</p>
    <p>Hii ni blogi yangu. Nitaandika machapisho juu ya maisha yangu, na ya safari zangu. Wakati nipo Kenya, nitayaandika machapisho kwa Kiswahili <Smile type="8)" /> (na <ChLang lang="en" f={funcs.chlang}>Kiingereza</ChLang> pia). Lakini, machapisho mengini nimeyaandika kwa lugha zingine. <Smile type=":(" /></p>
    <p>Natumai upende <Smile type=":p" /></p>
  </div>,
};
