import React from 'react';
import Smile from '../Smile';
// import ChLang from '../ChLang';

export const mfangano = {
  title: {
    sw: "Nikaishi kisiwani",
  },
  subtitle: {
    sw: "Nikapata nyumba nchini Kenya",
  },
  url: "mfangano",
  img: "mfangano",
  country: "Kenya",
  city: "Kisiwa cha Mfangano",
  post: {
// ’ “” – …
    sw: <div id="main-post-text">
      <p>Host yangu ajayo anaishi kisiwa cha Mfagano, katika Ziwa Victoria. Nilimtana wakati alikamatwa na polisi. <Smile type=":o" /> (Rafiki yake amekuwa na matatiza ya pikipiki.)</p>
      <p>Tukaenda (bila kumsaidia rafiki <Smile type=":(" />) na tukasafiri siku yote. Tukakuja Ziwa Victoria baada ya meli ya mwisho ilienda. Tukalala katika nyumba ya rafiki mwingine wa host yangu, nitayoita “Peter”. <Smile type=";)" /></p>
      <p>Nilipofika nyumba yake, nilipata ugonjwa mkubwa. Kwa siku nchache, nilikuwa mbaya sana. <Smile type=":(" /></p>
      <p>Lakini, baada ya nilikuwaa bora, nikafanya vitu vingi na “bibi” (mke) wa Peter (nitayoita “Betty”). Nikajifunza kiSwahili kingi na msaada wa watu wote (hata watoto). Nikajifunza kupika na Betty. Nikajifunza kuosha vyombo na yeye, na nikaogela (hapa, “kuswim”) sana. <Smile type="8)" /></p>
      <p>Wamenisema nitarudi mwaka ujao mweza wa sita, na nitakaa hapa miezi mitatu, mpaka mweza wa tisa. Ningependa hii itokee. <Smile type=":)" /></p>
    </div>,
  },
};
