import React, {Component} from 'react';
import './Window.css';

class Window extends Component {
  render() {
    const {lang, chlang, title, Comp, path, setUrl, loadNum} = this.props;
    return (
      <div className="window">
        <div className="window-box">
          <h2
            className="window-header"
            onClick={()=>setUrl("")}
          >{title}</h2>
          <Comp
            lang={lang}
            chlang={chlang}
            path={path}
            setUrl={setUrl}
            loadNum={loadNum}
          />
        </div>
      </div>
    );
  }
}

export default Window;
