import React from 'react';
import Smile from '../Smile';

// ’ “” – …
export const dancing = {
  title: {
    en: "I Don’t Dance…",
  },
  subtitle: {
    en: "(Except in special circmstances.)",
  },
  url: "dancing",
  img: "jane-dancing",
  country: "USA",
  state: "Louisiana",
  city: "Lafayette",
  post: {
    en: <div id="main-post-text">
      <p>Yesterday I did something that I don’t ever normally do: I went to a bar. <Smile type=";)" /></p>
      <p>Pretty normal for a young woman alone on the road, right? <Smile type=":)" />  Well, for me, not quite. I don’t drink. I don’t play any instruments. I really have absolutely no reason to be in any proximity to a bar – except that my Couchsurfing host (Let’s call him “Stan”) offered to take me to one of his favourite spots in the city, and Eid al-Fitr had just started at sunset, so the time seemed ripe for a celebration. <Smile type="8)" /></p>
      <p>Stan’s “place” was a hostel in town – which had an open-air bar in the back. Along with a dance floor and a live band playing music – traditional Cajun music, according to Stan. All good as we walked up. But then Stan left me to get some “fresh air” (his code name for taking a smoke break), and I was left to venture up into the crowd on my own. <Smile type=":o" /></p>
      <p style={{fontSize: "smaller"}}>(Yes, I’m aware that we’re still in a pandemic. But 1) I’m vaccinated, 2) it was all outdoors, and 3) social distancing was SO 2020 <Smile type=":p" />)</p>
      <p>So I went up on my own. Trying to look engagable in conversation – a decent socialising strategy for a lone woman, I thought <Smile type=";)" /> – but no one engaged. I wasn’t sure how to comport myself. There were a cluster of young people all hanging out by the wall, and I went up and asked if they were travelling here.</p>
      <p>I’d been with university students for most of the past month – granted, mostly grad students, but I feel like I’ve been fitting in. And these people looked college age. But the three or four of them I talked to all, after telling me they were a college group from Mobile (they pronounced it “mo-BEEL”), finished their sentences with an emphatic “<i>ma’am</i>” that made me feel old and not welcome to hang out with them. <Smile type=":(" /></p>
      <p>I wandered around a bit more, not sure what to do, until I finally gave up and sat down alone at a bench, probably looking about as misplaced as I felt. <Smile type=":/" /></p>
      <p>But that was when I finally got the engagement I’d been hoping for. <Smile type=":)" /> An older man came up to me and asked if he could take me out for a dance.</p>
      <p>Okay: a bit of back-story here. I don’t dance. I’m tone-deaf. My coordination is zero. (I actually have a minor physical disability that affects that <Smile type=":k" />) Bottom line: if I wanted to look like I fit in, dancing was the last thing I should do.</p>
      <p>And yet, it just goes to show how silly I felt, wandering around being called “ma’am” like an old lady – because the next thing I knew I was being led out to the dance floor. <Smile type=":o" /> <Smile type=":)" /></p>
      <p>I told him I didn’t know how to dance. I told him I couldn’t do any spins or such. And yet, when he led me out, I was surprised. Dancing the dance that I didn’t know wasn’t nearly as hard as I’d feared. And this older man was an excellent lead. Even for someone who literally didn’t know the first thing about dancing, he guided me gently into the sorts of spins and twirls that I’d sworn I couldn’t do. I only fumbled once – “Don’t turn unless I turn you!” – but compared to what I’d expected, I thought I was doing amazing. <Smile type=";)" /></p>
      <p>I found Stan after the dance. He’d come back while I was making a fool of myself – and even got it on video! <Smile type=":p" /> He knew that man – apparently he asked all younger girls to dance, to teach us the Cajun dance that the young ’uns were forgetting, and keep it alive. Well, I might not be keeping Cajun dance alive, but I’m honoured to have had the invitation. <Smile type=":D" /></p>
      <p>It was only after the fact, reminiscing on my weird night as I tossed and turned on Stan’s couch (it’s called “Couchsurfing” for a reason <Smile type=":p" />) that I reflected on how my gender must’ve played a role in it all. (How could I not; I’m the author of something called “the vagina travelogues”, after all! <Smile type=";)" />) I wondered exactly how much of what happened to me happened precisely <i>because</i> I’m a woman. And the honest truth is: I think a fair bit of it. If I weren’t a woman, I never would’ve been asked up to dance. If I weren’t a woman, my initial shy fumbling around the bar might not have endeared me to people there; they might’ve called security. Now I’m not saying that there’s not a lot that sucks about how the world treats lone women (and I’ll rant all about that in another blog soon <Smile type=":k" />); I’m just saying that there can be perks, too. <Smile type="8)" /></p>
      <p>All in all, my Eid al-Fitr started with something I don’t normally do while I’m on the road (or <i>ever</i>, honestly): I went dancing at a bar! <Smile type=":p" /></p>
    </div>,
  },
};
