import React from 'react';
import Smile from '../Smile';

// ’ “” – …
export const foodbank = {
  title: {
    en: "I think I ate at the food bank",
  },
  subtitle: {
    en: <span>To be fair, I <u>have</u> been homeless.</span>,
  },
  url: "foodbank",
  img: "foodbank",
  country: "USA",
  state: "Colorado",
  city: "Denver",
  post: {
    en: <div id="main-post-text">
      <p>During my layover in Denver, Colorado, I did something that I doubt most of my readers have done: I took some food from what I think was a food bank <Smile type=":o" /></p>
      <p>It wasn’t like I tried to go to the food bank or anything. <Smile type=";)" /> In general, I try to leave social services for people who are actually in need. But here’s how it happened. I went to the capitol – because I’m collecting <a href="https://www.janestrut.com/en/pics/capitols">photos of state capitols</a> now <Smile type=";)" /> – and there were a few people out handing out food, and a queue.</p>
      <p>Did the people taking food look like homeless people? Sure. But did <i>I</i> look like a homeless person? Sure. I was wearing a pretty large backpack crammed with most of the stuff I’d been dragging around with me for the last three months, and probably looked like someone who’d spent last night sleeping on grass in a sleeping bag, because that’s exactly what I’d done. <Smile type=":p" /></p>
      <p>So they offered me food. And I accepted it. <Smile type=":)" /></p>
      <p>Now, back up. What the hell was I even doing in Colorado? If you read the posts surrounding this one (when I finally get around to making them <Smile type=";)" />), you’ll think I was in the South, or California. But <i>Denver</i>?</p>
      <p>I was trying to rejoin my family, and get to my cousin’s wedding, in Southern California. I originally had a flight on Sun Country from Cincinnati to Long Beach. I bought it because it was cheap, but already I wasn’t thrilled with the arrangements. <Smile type=":/" /> The flight had a mere three-hour layover in Minneapolis – or, in other words, way too short a time to actually do anything with it. <Smile type=":(" /> It also seemed to be one of those crappy airlines that doesn’t even let you take a “carry-on”, but only a “personal item”. <Smile type=":k" /></p>
      <p>So I was already stressed about it. But then I became aware that the wedding was a day later than I thought – so I had an extra day. <Smile type="8)" /> Time to change my flight plans!</p>
      <p>A normal person might’ve taken the chance to relax… in Cincinnati, where I was! <Smile type=";)" /> But I’m not a normal person. Playing around with the Skyscanner app, I discovered something. For not much more than my original flight had been, I found a better option:</p>
      <ul>
        <li>It landed at San Diego, rather than Long Beach – which was a <i>lot</i> closer to where my parents would be. <Smile type=":D" /></li>
        <li>Rather than touch down at 8PM or whatever, it was scheduled to arrive at 5:30 – so it’d still be light out! <Smile type=":D" /></li>
        <li>It wasn’t on anything weird like “Sun Country”. It was with United. And that’s a well-known, reputable airline. <Smile type=":D" /></li>
        <li>Rather than take off from Cincinnati, it left from Indianapolis. Not a problem for me! An excuse to see a new city. <Smile type=":D" /></li>
        <li>And it had a seven hour layover in Denver. <Smile type=":D" /></li>
      </ul>
      <p>Okay, hold up. You’re probably thinking that last cheerful smile (<Smile type=":D" />) was a mistake. Had I just grown so used to sticking them in that I forgot, and expressed glee over a seven hour layover?</p>
      <p>Nope. I <i>was</i> excited about this! The reasoning being: three or four hours is way too short to actually <i>do</i> anything. But with seven whole hours? Why, then you can get out, and really <i>see</i> the city. Even make a trip to the food bank! <Smile type=":p" /></p>
      <p>A stroke of genius, right? <Smile type=":)" /> Well, not quite… First, Sun Country charged $50 to cancel my $110 ticket, <Smile type=":k" /> and didn’t even refund the rest, but gave me a voucher. So I guess I’ll have to go to Minneapolis soon! <Smile type="8)" /> Then, it turned out that the United ticket, while not much more in price than Sun Country had been, was a “basic economy” ticket. It had said multiple times that “basic economy” meant no “full-size carry-on”. Well, neither of my two items was that large. So I didn’t worry about it – until I got the email from them the day before the flight, where they made it quite clear that no “full-size carry-on” meant no carry-on, period. I had to check on my bag for an additional $35. <Smile type=":k" /></p>
      <p>But I was now blessed with a seven-hour layover in Denver. My flight from Indianapolis was, mercifully, on time (and I was right there, as I slept on the grass just outside the airport <Smile type=":p" />). So I got to Denver in the morning.</p>
      <p>And took right off. I wanted to buy the cheapest round-trip ticket for the train, because I’d be back to the airport that evening. But the machine didn’t give me a choice. It only sold “day passes” for about $10. Fine. Give me a “day pass”. It worked on all Denver-area transit, and so I rode the train downtown, walked to the capitol, took the tram back to the train station, and then took a bus to Boulder, and another bus from Boulder back to the airport. If I had to buy a “day pass”, I made sure to take advantage of it! <Smile type=":p" /></p>
      <p>So, truthfully, the food bank experience was a very small part of my ten hours in Colorado. I was running around – to be honest – a little bit like a chicken with her head cut off. But I had a good time. And I didn’t steal food from homeless people, if that’s what you’re thinking. Even though I fit right in with the crowd. <Smile type=":p" /> And, technically, I <i>had</i> been homeless that last night – I’d slept on the grass outside the Indianapolis airport. But I didn’t demand heaps of food. I’m a freegan; not someone happy to take food that’s actually being used for a cause. I only asked for a banana… and they gave me two <Smile type=":D" /></p>
      <p>My only regret was that I had almost no time in Boulder. I only went there, honestly, because the “day pass” made it free. But I didn’t even get to the university – where two of my parents’ neighbours went to college. I walked down Pearl Street. I bought a vegan yoghurt to eat with my banana for dinner – and discovered that the cashier had the same phone case as I did. <Smile type="8)" /> But then I had to run back to the airport to catch my plane.</p>
      <p>Which turned out to be delayed for three hours. And, rather than telling us with any advance notice, United merely updated the “expected departure” time every twenty minutes. <Smile type=":k" /> They didn’t offer me any compensation, or anything.</p>
      <p>I don’t like flying that much in general, but I’d definitely advise against United. I’ve flown on American seven times since the start of the pandemic, and that’s definitely a more reasonable airline! <Smile type=":/" /></p>
    </div>,
  },
};
