import React from 'react';
import Smile from './Smile';
import ChLang from './ChLang';

const genres = {
  fiction: {
    en: "fiction",
    es: "ficción",
    ru: "фантастика",
    pt: "ficção",
    sw: "hadithi",
  },
  nonfiction: {
    en: "non-fiction",
    es: "no ficción",
    ru: "документальный",
    pt: "não-ficção",
    sw: "hadithi za kweli",
  },
  memoir: {
    en: "memoir",
    es: "memoria",
    ru: "мемуары",
    pt: "memória",
    sw: "kumbukumbu",
  },
  poetry: {
    en: "poetry",
    es: "poesía",
    ru: "поэзия",
    pt: "poesia",
    sw: "ushairi",
  },
};

const langs = {
  english: {
    en: "English",
    es: "inglés",
    ru: "английский",
    pt: "inglês",
    sw: "Kiingereza",
  },
  spanish: {
    en: "Spanish",
    es: "español (castellano)",
    ru: "испанский",
    pt: "espanhol",
    sw: "Kihispania",
  },
  russian: {
    en: "Russian",
    es: "ruso",
    ru: "русский",
    pt: "russo",
    sw: "Kirusi",
  },
  portuguese: {
    en: "Portuguese",
    es: "portugués",
    ru: "португальский",
    pt: "português",
    sw: "Kireno",
  },
  swahili: {
    en: "Swahili",
    es: "suajili",
    ru: "суахили",
    pt: "suaíli",
    sw: "Kiswahili",
  },
}

export const window_description = {
// ’ “” –
  en: <div className="window-description">
    <p>First and foremost, I’m a writer. I generally consider myself a fiction writer, but I’ve dabbled in other genres as well <Smile type=";)" /></p>
    <p>I’ve got like eight novel drafts. (I’ve lost count! <Smile type=":o" />) Noting published yet, but a girl can dream… <Smile type=":p" /></p>
 {/* And I’ve written a fair amount about gender. I’m not anything like gender non-binary, and I don’t consider myself “trans*”, but I <i>have</i> had my fair share of exposure to gender issues. I consider myself a cis girl born 29 years (and 36 days) too late.</p>
    <p>You say, “<i>What</i>?” Well, I was born inside – literally trapped inside – a body with guy parts. Not too unusual, actually. It happens. But what I did in response is way off the charts.</p>
    <p>Most people who have that happen try to live their lives in that wrong body, maybe insisting, “I’m really a ___!” But I flat-out refused. I took to living fictionally, in worlds that I created myself, with people made up out of my own head.</p>
    <p>And in the real world? I let that male body run mostly on autopilot. For almost thirty years, the person controlling me wasn’t me; it was a freak, built up by society, whom I call “Hell Guy” <Smile type=":K" /></p>
    <p>While I was trapped inside Hell Guy, the only way that I – a woman – could exist was in fiction. So I made Hell Guy write a whole lot of fiction. Where more often than not I myself appeared as one of the characters <Smile type="8)" /> Nothing got published (except for one silly poem in Spanish <Smile type=":/" />) but a good deal got written, and it’s made me a writer.</p>
    <p>Now that I have a real life, I write non-fiction too. In fact, my writing dabbles all over the place <Smile type=":p" /> But I’ve done my best here to compile a complete bibliography. I hope you find something worthwhile <Smile type=":)" /></p>*/}
  </div>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
  es: <div className="window-description">
    <p>Soy escritora. Y sí, yo digo «escritora», con A – aunque quizás, para las personas modernas, esto parezca sexista <Smile type=":/" /> No me importa. Amo ser una mujer – y amo existir <Smile type=":D" /></p>
	<p>Por mucho de mi vida, yo casi no existía. O sea, sólo existía a través de escritura. ¿Te confundo? Bueno, me confundo a mi misma <Smile type=":p" /> Pero mi vida es así: complicada… <Smile type=":o" /></p>
    <p>Espero que tú descubras algo – en toda la locura que yo he producido – que vale la pena <Smile type=";)" /></p>
  </div>,
// ё — «»
  ru: <div className="window-description">
    <p>Я—писатель. Я люблю писать. Мне тоже нравится читать, но письмо долго было для меня единственным способом существования <Smile type=":o" /></p>
    <p>Это странное? Да, это—очень странное. Но моя жизнь—очень странное <Smile type=":p" /></p>
    <p>Единственная вещь, что я знаю: я существую. Я существую и я пишу. Это всё что знаю, а это—всё что мне нужно знать <Smile type=":D" /></p>
  </div>,
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
  pt: <div className="window-description">
    <p>Eu nunca publiquei nada em português. <Smile type=":o" /> Quando escrevi muito disso (do que está neste site) eu nem sabia o português. A minha escrita é quase tudo em inglês, com uma única história em espanhol. Para mim, esta língua, o português, é bastante nova <Smile type=":)" /></p>
    <p>Bem, eu espero que você poda ler em outras línguas! <Smile type=":p" /></p>
  </div>,
};

// ’ “” –
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
// ё — «»
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”

export const pubs = [
  {
    image: '/images/publications/fiveonthefifth.png',
    title: "I Read, Much of the Night, and Go South in the Winter",
    description: {
      en: <p>Just during the beginning of COVID, I was whisked around from house to hospital to AirBnb trying to get to my parents in the USA <Smile type=":o" /> During which time I wrote this story: based on the wonderful experiences I had in Pakistan before COVID shut the country down <Smile type=":/" /></p>,
      es: <p>Esto es un relato (escrito en inglés) que – me siento – es mi más auténtico. Se trata de Pakistán – un país que yo estaba discubriendo antes de que la pandemia global acabó con todo <Smile type=":K" /></p>,
      ru: <p>Я написала это в Пакистане, до того, как COVID-19 закончил мои путешествие туда… <Smile type=":(" /></p>,
      pt: <p>Eu escrevi isso uns meses antes de viajar ao Brasil e aprender o português; mas eu sinto que foi muito mais longo, porque a pandemia — que apenas começou nessa época — mudou tanto o mundo <Smile type=":(" /></p>,
      sw: <p>Niliandika hadithi hii wakati nimekuwa Pakistan. Ninapenda hadithi hii… <Smile type=":)" /></p>,
    },
    url: "https://www.fiveonthefifth.com/vol-5-issue-7-story-2",
    genre: genres.fiction,
    mag: "Five on the Fifth",
    mag_site: "https://www.fiveonthefifth.com/",
    language: langs.english,
  }, 
  {
    image: '/images/publications/navajo.jpg',
    title: "A Field Trip To Some Of The Last Land Still Owned by Americans",
    description: {
      en: <p>The ironic thing about this one is that I never expected it published! I did it as a ghostwriting-ish exercise for a client on Upwork. And then he published it on his website <Smile type=":p" /> {/* And it comes up when you <a href='https://duckduckgo.com/?q="jane+sofia+struthers"'>Google my name (or DuckDuckGo my name <Smile type=";)" />)</a>. */} I guess that makes this the only piece of writing I’ve been paid for – $10USD I think <Smile type="8)" /></p>,
    },
    url: "https://www.mobilepermissions.com/2020/01/08/benefits-of-field-trips-the-navajo-nation/",
    genre: genres.nonfiction,
    mag: "Mobile Permissions",
    mag_site: "https://www.mobilepermissions.com/",
    language: langs.english,
  }, 
  {
    image: '/images/publications/kittens.jpg',
    title: "Depends On How Many Kittens",
    description: {
      en: <p>My first piece of published fiction! <Smile type=":D" /> If you were born in the wrong body, and only wanted to get into the right one, how many kittens would you be willing to sacrifice to do it? This is a creative look into trans rights, set in Australia and based on a recent trip I made there.</p>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
      es: <p>¿Que harías, sí tú fueres atrapad@ en un cuerpo horriblemente equivocado, que aún no te parecía el tuyo? <Smile type=":o" /> Esto es mi primero relato publicado en ficción. Con esto, ¡me convertí en una autora verdadera <Smile type=":D" />!</p>,
// ё — «»
      // ru: <p>Эта история случается в Австралии потому, что я была там. И это—про транс-сообщество потому, что у меня были гендерные проблемы <Smile type=":(" /> К счастью, теперь они исправились <Smile type=":D" /></p>,
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
      // pt: <p>Esta história vem de uma compreensão que eu tive: que eu não sou o monstro que todos me disseram que seja. <Smile type=":o" /> <Smile type=":D" /> Mas eu pensei, como seria isso se a minha familia, e os meus amigos, foram piores, e não me apoiassem <Smile type=":K" /> Disso vem a minha primeira história ficcional publicada <Smile type=";)" /></p>,
      sw: <p>Hadithi hii ni hadithi wangu kwanza waliyochapishwa. <Smile type=":p" /></p>
    },
    url: "http://rightnow.org.au/creative-works/depends-many-kittens/",
    genre: genres.fiction,
    mag: "Right Now",
    mag_site: "http://rightnow.org.au/",
    language: langs.english,
  }, 
  {
    image: '/images/publications/homeless.jpg',
    title: "Homeless in Sacramento",
    description: {
      en: <p>This is the true story of a crazy girl (me!) who decided to spend two nights on the streets of my hometown of Sacramento, California. It’s also my first major publication <Smile type=":)" /></p>,
      es: <p>Esto es la historia verdadera de una chica loquísima (¡yo!) quien decidí pasar dos noches sin hogar, durmiendo en las calles (o, en verdad, a lo largo del rio) de mi ciudad natal <Smile type=":o" /></p>,
      ru: <p>У меня на свой двери были написаны две цели. 1: жить 24 часа на улице, и 2: писать что-то об этом. Я сделала оба! <Smile type=":o" /> <Smile type=":D" /></p>,
      pt: <p>Eu queria saber como sinte estar na minha cidade natal, sem lar. Bem, eu consegui <Smile type=":p" /></p>,
      sw: <p>Nilitaka kuishi na wata wasio na makazi <Smile type=":o" /> na kuandika juu yake <Smile type=";)" /></p>
    },
    url: "http://potatosoupjournal.com/homeless-in-sacramento-by-jane-struthers/",
    genre: genres.memoir,
    mag: "Potato Soup Journal",
    mag_site: "http://potatosoupjournal.com/",
    language: langs.english,
  }, 
  {
    image: '/images/publications/pajaro.jpg',
    title: "Pájaro",
    description: {
      en: (funcs) => <p>I think that this was only accepted for the novelty factor. (Most of it’s in <ChLang lang="es" f={funcs.chlang}>Spanish</ChLang>.) To be honest, it’s not very good… <Smile type=":/" /> Bottom line: I’m not a poet! <Smile type=";)" /></p>,
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «»
      es: <p>Yo olvidaba – que yo sí tengo una sola publicación (por el momento) en una lengua distinta que el inglés. Es esto <Smile type=";)" /> Un poema escrito en español. Fuera de esta noveltad, el poema no vale la pena leer. Tampoco me gusta. <Smile type=":(" /> Pensé en removerlo de aquí… pero sí tiene mi nombre. Así es… <Smile type=":/" /></p>,
// ё — «»
//      ru: <p>Это—мой единственные письмо на испанском <Smile type=";)" /> Прямо сейчас, это—мой единственный письмо на другом языке, кроме английского. Это не очень хорошо, но думаю что они его опубликовали потому, что оно было иначе <Smile type=":p" /></p>,
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “”
      pt: <p>Um poema em espanhol, publicado numa revista inglesa? Seguramente, isso só foi publicado pela novidade! <Smile type=":o" /> <Smile type=":p" /></p>,
      sw: <p>Shairi hili si zuri… <Smile type=":(" /> Jarida hili la mashairi ya Kiingereza limechapisha shairi la Kihispania hii tu kwa sababu ya shairi ni geni. <Smile type=":/" /></p>
    },
    url: "https://rustandmoth.com/work/pajaro/",
    genre: genres.poetry,
    mag: "Rust + Moth",
    mag_site: "https://rustandmoth.com/",
    language: langs.spanish,
  },
];
