import React, {Component} from 'react';
import Smile from './Smile';
import './Blog.css';
import {blog, window_description} from './blog-posts';

const blog_name = {
  en: "The Vagina Travelogues",
  es: "Los viajálogos de vagina",
  sw: "Mazungumzo ya Safiri za Uke",
};

const country_word = {
  en: "Country",
  es: "País",
  pt: "País",
  sw: "Nchi",
};
const state_word = {
  en: "State",
  es: "Estado",
  pt: "Estado",
  sw: "Jimbo",
};
const city_word = {
  en: "City",
  es: "Ciudad",
  pt: "Cidade",
  sw: "Mji",
};
const top_word = {
  en: "Top",
  es: "Arriba",
  pt: "Acima",
  sw: "Juu",
};

function Post(props) {
  const {lang, chlang, data} = props;
  if (!data || !data.title || !data.title[lang]) {return null;}
  const post_title = <h2 id="main-post-title">{data.title[lang]}</h2>;
  const post_subtitle = (data.subtitle && data.subtitle[lang])
    ? <h3 id="main-post-subtitle">{data.subtitle[lang]}</h3>
    : null;
  const image = (data.img) ? <img
    id="main-post-img"
    src={"/images/blog/" + data.img + ".jpg"}
    alt=""
  /> : null;
  const location = <ul id="main-post-location">
    {data.country && <li><b>{country_word[lang]}:</b> {data.country}</li>}
    {data.state && <li><b>{state_word[lang]}:</b> {data.state}</li>}
    {data.city && <li><b>{city_word[lang]}:</b> {data.city}</li>}
  </ul>;
  const post = (data.post && typeof data.post[lang] === 'function') ? 
    data.post[lang]({chlang}) :
    data.post[lang];
  return <div id="main-post">
    {post_title}
    {post_subtitle}
    {image}
    {location}
    {post}
    <a href="#top" className="to-top">↑ {" " + top_word[lang] + " "} <Smile type=":)" /></a>
  </div>;
}

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_post: null,
      loadNum: 0,
    };
  }
  openPost(post) {
    const {lang} = this.props;
    const title = (post && post.title && post.title[lang]) ?
      post.title[lang] : "";
    const {url} = post;
    this.props.setUrl(url);
    if (!title) {post = null;}
    this.setState({
      current_post: post,
    });
  }
  componentDidMount() {
    // If there's a URL, show that post.
    if (this.props.path && this.props.path.length) {
      const url = this.props.path[0];
      var i;
      for (i=0;i<blog.length;i++) {
        if (blog[i].url === url && blog[i].title[this.props.lang]) {break;}
      }
      if (i < blog.length) {
        this.setState({current_post: blog[i]});
      }
    }
    else {this.setState({current_post: null});}
  }
  render() {
    if (this.state.loadNum != this.props.loadNum) {
      this.setState({current_post: null, loadNum: this.props.loadNum});
    }
    var {current_post} = this.state;
    var {lang, chlang} = this.props;
    const post = (current_post) ? 
      <Post data={current_post} lang={lang} chlang={chlang} /> : null;
    const post_list = blog.map(
      (post, i) => {
        if (!post.title || !post.title[lang]) {return null;}
        const img_preview = (post.img) ?
          <img
            className="post-listing-image"
            src={"/images/blog/" + post.img + "-tiny.jpg"}
          /> : null;
        var locator = [
          post.city,
          post.state,
          post.country,
        ];
        const location = locator.filter(val => val && val.length).join(", ");
        return <div
          className="post-listing"
          onClick={() => this.openPost(post)}
        >
          <div className="post-listing-inner-div">
            <div className="post-listing-title">{post.title[lang]}</div>
            <div>{img_preview}</div>
            <div className="post-listing-location">
              — {location} —
            </div>
          </div>
        </div>;
      }
    );
    return <div id="blog">
      <h2 id="blog-title">{blog_name[lang]}</h2>
      {window_description[lang]}
      <div id="post-list">
        {post_list}
      </div>
      {post}
    </div>;
  }
}

export default Blog;
