import React from 'react';
import Smile from '../Smile';
import ReadMore from '../ReadMore';
import ChLang from '../ChLang';

export const kenya = {
  url: "kenya",
  folder: true,
  title: {
    en: "Kenya",
    sw: "Kenya",
  },
  description: {
// ’ “” – …
    en: (funcs) => <div className="folder-description">
      <p>I went to Kenya to do absolutely nothing touristy, <Smile type=":o" /> but meet the Kenyan locals and learn Swahili. So I’m writing this from a rural house near a town you’ve never heard of <Smile type=":(" /> (Meru, Kenya), in the living room of some wonderful and extraordinarily hospitable <Smile type=":D" /> people whose daily earnings are less than what you probably paid for your coffee this morning at Starbucks. I’m only going to present my picturs here in <ChLang lang="sw" f={funcs.chlang}>Swahili</ChLang>; I’m dead serious. <Smile type=":/" /></p>
    </div>,
    sw: <div className="folder-description">
      <p>Nilinunua tiketi za ndege kwenda Kenya, kwa sababu niliweza (na vikwazo vya COVID) na nilitaka kusafiri Afrika. Tiketi za Turkish Airlines zilikuwa za bei rahisi. Kwa hivyo, nilinunua.</p>
      <p>Huko, ndio, niliona wanyama. Twiga, tembo, pundamilia… Sijapiga picha zao. Sikujali. Nilitaka kujua watu, na nilijua wengi, ata nilikuwa na mpenzi mKenya. Sasa sijaona simba, lakini najua kwamba “Simbe” si jina la mhusika wa sinema tu. <Smile type=":p" /></p>
      <p>Mimi sio kama wazungu wengi. Natumai untaona hivi katika picha zangu. <Smile type="8)" /></p>
    </div>,
  },
  photos: [
    {
      url: "meru",
      title: {
        sw: "Meru",
      },
      description: {
        sw: <p>Kitu cha kwanza nilifanyacho katika Kenya kilikuwa kutembelea mtu nimejuaye toka Couchsurfing. Nilienda nyumba ya familia yake… <Smile type=":)" /> kwa kujua tu, baada ya muda mfupi, wanaogopa majirani wakijua kwamba muzungu (mimi) anakaa nao. Nilikuwa nimefichwa kabisa nao. <Smile type=":k" /></p>
      },
    },
    {
      url: "jikoni",
      title: {
        sw: "Jikoni",
      },
      description: {
        sw: <p>Mtu mmoja wa Workaway alisema kwamba mtu hajatembelea nchi mpya kwa ukweli asipokuwa jikoni pa wenyeji. <Smile type=":o" /> Sawa. Hili ni jiko lao.</p>
      },
    },
    {
      url: "barabara",
      title: {
        sw: "Barabara ya Kenya",
      },
    },
    {
      url: "one-africa-place",
      title: {
        sw: "One Africa Place",
      },
      description: {
        sw: <p>Katika Kenya, kuna watu tayari. Na kuna watu wanaowalinda. <Smile type=":/" /></p>
      },
    },
    {
      url: "mfangano",
      title: {
        sw: "Kisiwa cha Mfangano",
      },
      description: {
        sw: <p>Kisiwa hiki hakipo baharini. Kipo Ziwani Victoria. <Smile type=":p" /></p>
      },
    },
    {
      url: "bote",
      title: {
        sw: "Maisha ya watu wa Mfangano",
      },
      description: {
        sw: <p>Ni kwa hivyo. <Smile type=":)" /></p>
      },
    },
    {
      url: "wazungu",
      title: {
        sw: "Wazungu!",
      },
      description: {
        sw: <p>Wakati wote nilipokuwa huko Kenya, sikuona wazungu mmoja (haliyekuwa mimi <Smile type=":(" /> nje ya tour kama hizi. <Smile type=":(" /></p>
      },
    },
  ],
};
