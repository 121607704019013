import React, {Component} from 'react';
import Smile from './Smile';
import './Cooking.css';
import {recipes} from './cooking/recipes';
import Recipe from './cooking/Recipe';

// Stealing the "Kathy rule" map from the travel section :p
const map_kathy_rule = <img
  className="window-block-image"
  src="/images/map-kathy-rule.png"
 />;
const workaway_link = <a
  href="https://www.workaway.info"
  target="_blank"
  rel="noopener noreferrer"
>Workaway</a>;

const window_description = {
// ’ “” – …
  en: <div className="window-description">
    <p>I’m also (would you have guessed it?) kind of a chef. At least, I like cooking. Plan to open my own restaurant some day; keep an eye out for the “businesses” tab <Smile type=";)" /></p>
    <p>I’ve cooked in various countries. (You can see pics of me cooking in other countriess by visiting this section in other languages.) Food is a huge part of local culture, and one of the things that really separates “tourists” from “travellers” like me. In fact, in Bulgaria there was a {workaway_link} host (“Kathy”) who said, “You haven’t really <i>been</i> to a country until you’ve been inside of somebody’s kitchen.” Fair enough. But my world map by the “Kathy rule” isn’t looking too shabby! <Smile type="8)" /></p>
    {map_kathy_rule}
    <p>I’ve bounced various ideas around my head, such as starting a food blog, but for the moment, this is all that I’ve got <Smile type=":o" /> And I’m in the middle of internationalising (“i16g”?) my site, so I’m re-hashing this whole section and most of the recipes I used to have are offline now. But never fear! There’ll be more <Smile type=":)" /></p>
    <p>So that’s me – a chef on top of everything <Smile type=":p" /> A VEGAN chef, I might add. I’ve been vegetarian since the 90’s, and in 2018 I cut out milk, eggs, and honey. The recipes here are entirely animal-product-free <Smile type=":D" /></p>
  </div>,

// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
  es: <div className="window-description">
    <p>Me gusta cocinar. Es más que experimentar la cocina de varias culturas. (¡Aunque sí es increíble conocer el mundo así!) Para mí, simplemente, cocinar es algo bien agradable <Smile type=":p" /></p>
    <p>Pero mis recetas, y la comida que me gusta, sí vienen de todas partes del mundo. Y yo he cocinado alredador del planeta. Una anfitriona en el sitio web {workaway_link} (llamada Kathy) dijo que no has visto un país, realmente, hasta que hayas visto el interior de una cocina. A ver como va mi mapa de viajes según esta regla:</p>
    {map_kathy_rule}
    <p>Vale, he “realmente” visto mucho del mundo… ¡pero siempre hay más <Smile type=";)" />! Y siempre yo voy a ir aumentando mi sabiduría culinaria. Aqui pondré algunas de mis recetas favoritas, inclusas con un cuento breve de cómo las he encontrado o creado <Smile type=":)" /> También, en los otros idiomas, a veces se presentarán recetas diferentes <Smile type=":o" /> El objetivo es siempre mantenerlo interesante! <Smile type="8)" /></p>
    <p>¡Espero que disfrutes!</p>
  </div>,

// ё — «» …
  ru: <div className="window-description">
    <p>Я люблю готовить. Поэтому этот раздел существует. Другой причины нет. <Smile type=";)" /></p>
    <p>Если вам понравится моя веганская кулинария, может быть, вы меня пригласите в Россию <Smile type=":p" /></p>
    <p>Хотя я никогда не готовила в России, я напишу (и перевожу) свои рецепты на русский язык. Таким образом, я могу практиковать и улучшить свой русский язык.И, даже если вы говорите только по-русски, вы можете наслаждаться моими веганскими рецептами <Smile type=":D" /></p>
    <p>Кроме того я хочу учиться больше русские рецепты. (Единственный что сейчас знаю—<i>гречка</i> <Smile type=";)" />) Когда я могу путешествовать в Россию, я буду готова!</p>
    <p>Пока я жду путешествовать в Россию, посмотрите этот сайт – где буду добавить много веганских рецептов! <Smile type="8)" /></p>
  </div>,

// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …
  pt: <div className="window-description">
    <p>Uma coisa que eu, sim, fiz muito no Brasil foi cozinhar. Antes de viajar para o Brasil, eu já me considerava cocineira. E no Brasil, as minhas receitas diversificaram. <Smile type=":)" /></p>
    <p>O motivo foi, em grande parte, porque eu sou vegana. E no Brasil quase todo mundo come a carne ou o pescado <Smile type=":o" /> Eu tinha que inventar as minhas próprias receitas, só para poder sobreviver!</p>
    <p>Agora vou compartilhá-las com todo mundo, inclusas as criações que eu inventei no Brasil. Eu vou continuar a publicá-las no site. E muitos serão disponíveis apenas neste idioma – o português! <Smile type=":p" /></p>
    <p>Eu espero que vocês gostem delas! <Smile type=":D" /></p>
  </div>,
};

class Cooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      showKcal: false,
      servings: 1,
      loadNum: 0,
    };
  }
  setActive(i) {
    const a = (this.state.active === i) ? -1 : i;
    if (a < 0) {this.props.setUrl("");}
    else {this.props.setUrl(recipes[a].name[this.props.lang].replace(/ /g, "-"));}
    this.setState({active: a});
  }
  changeServings(val) {
    // Exit on invalid values or too big
    if (val < 0 || val > 50) {return;}
    this.setState({servings: val});
  }
  componentDidMount() {
    const {path, lang} = this.props;
    if (path && path.length && path[0]) {
      const name = this.props.path[0];
      // Find it and set it active.
      var a;
      for (a=0;a<recipes.length;a++) {
        if (!recipes[a].name[lang]) {continue;}
        if (decodeURI(name).replace(/-/g, " ") === recipes[a].name[lang]) {
          break;
        }
      }
      if (a < recipes.length) {
        this.setState({active: a});
      }
    }
  }
  render() {
    if (this.state.loadNum != this.props.loadNum) {
      this.setState({active: -1, loadNum: this.props.loadNum});
    }
    const {lang} = this.props;
    const recipes_list = recipes.map(
      (recipe, i) => (recipe.name[lang]) ? <Recipe
        key={recipe.name[lang]}
        data={recipe}
        lang={lang}
        scale={this.state.servings}
        active={this.state.active === i}
        showKcal={this.state.showKcal}
        setActive={()=>this.setActive(i)}
        style={{float: (i % 2 === 0) ? "left" : "right"}}
      /> : null
    );
    const {servings} = this.state;
    const serving_question = {
      en: "How many people are you serving?",
      es: "¿Cuántas personas vas a servir?",
      ru: "Скольким людям вы будьте обслужить?",
      pt: "Quantas pessoas você vai servir?",
    };
    const veryTiny = (servings > 0 && servings <= 0.5);
    const tinyServingNum = (veryTiny) ? 1/3 : 0.5;
    const tinyFunc = () => this.changeServings(tinyServingNum);
    const tinyServingText = (!veryTiny)
      ? {
        en: "I just want a tiny portion.",
        es: "Sólo quiero una porción pequeña.",
        ru: "Я просто хочу небольшую порцию",
        pt: "Eu só quero uma pequena porção",
      } : {
        en: "Even tinier!",
        es: "¡Aún más minúsculo!",
        ru: "Даже меньше!",
        pt: "Ainda mais minúsculo!",
      };
    const changeServingsFunc = (event) => {
      this.changeServings(Number(event.target.value));
    };
    const cooking_img = {
      en: "/images/cooking/jane-cooking-pk.jpg",
      es: "/images/cooking/jane-with-bhavik.jpg",
      ru: "/images/cooking/jane-cooking-brazil.jpg",
      pt: "/images/cooking/jane-cooking-brazil.jpg",
    };
    const cooking_alt = {
      en: "Me being a chef in Pakistan",
      es: "Comiendo con mi amigo indio",
      ru: "Я готовлю",
      pt: "Eu sou chef – no Brasil",
    }
    const cooking_title = {
      en: "I’m a chef even in Pakistan!",
      es: "Hice esto con mi amigo – y lo disfrutamos :)",
      ru: "Когда получу картинку о меня в России, я положу её сюда.",
      pt: "Sou chef no Brasil :D",
    };
    const calorie_button = (this.state.showKcal) ? {
      en: "Hide those pesky calorie counts",
      es: <span>¡No quiero contar calorías! <Smile type=":o" /></span>,
      ru: "Я не хочу думать о калориях!",
      pt: <span>Não quero pensar mais em calorias <Smile type=":o" /></span>,
    } : {
      en: "Show me the calories!",
      es: "Necesito saber cuántas calorías hay",
      ru: <span>Мне нужно считать калории <Smile type=";)" /></span>,
      pt: "Mostre-me as calorias!",
    };
    return (
      <div id="cooking">
        <img
          id="cooking-image"
          className="window-image"
          src={cooking_img[lang]}
          alt={cooking_alt[lang]}
          title={cooking_title[lang]}
      />
        {window_description[lang]}
        <div style={{clear: "both"}} />
        <div id="cooking-float-div">
          <div id="cooking-servings-div">
            {serving_question[lang]}
            <input
              type="number"
              value={servings}
              min="0"
              max="50"
              id="cooking-servings"
              onChange={changeServingsFunc}
            />
            { /* Only display this if theres a tinier option! */
            (servings <= 0 || servings > 0.4) ? (
              <button id="cooking-tiny-serving-button" onClick={tinyFunc}>
                {tinyServingText[lang]}
              </button>
            ) : null}
          </div>
          <button
            id="calories-button"
            onClick={()=>this.setState({
              showKcal: !this.state.showKcal,
            })}
          >
            {calorie_button[lang]}
          </button>
        </div>
        {recipes_list}
        <div style={{clear: "both"}} />
      </div>
    );
  }
}

export default Cooking;
