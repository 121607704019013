import React from 'react';
import Smile from '../Smile';
import ReadMore from '../ReadMore';
import ChLang from '../ChLang';

// ’ “” – …
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
// ё — «» …
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …

export const costarica = {
  url: "costa-rica",
  folder: true,
  title: {
    en: "Costa Rica",
    es: "Costa Rica",
    ru: "Это—Коста-Рика",
    pt: "Costa Rica",
  },
  description: {
    en: <div className="folder-description">
      <p>November 2020. Coronavirus infections were spiking, and the political sphere was heating up in the USA. <Smile type=":k" /> My parents’ solution? Get the hell out of dodge. “Dodge” being the USA itself, entirely. <Smile type=";)" /></p>
      <p>So we took off on a family trip — the likes of which I’d never been on. Now, my parents travel a lot. They bring the kids with them a lot. But, normally, they travel like tourists. <Smile type=":/" /> “We’ve got three days in Rome, so let’s do X, Y, and Z…”</p>
      <p>This was different. We didn’t go anywhere. <Smile type=":o" /> We didn’t see anything — except, every day, the ocean in front of our AirBnb beach house.</p>
      <p>It was awesome. <Smile type=":D" /></p>
    </div>,
    es: <div className="folder-description">
      <p>Qué raro mi experiencia en Costa Rica con mi familia…</p>
      <p>Lo que pasó es así. Mi familia no estábamos viajando. Estábamos huyendo de los EE UU — con las elecciones de noviembre de 2020, Donald Trump, el coronavirus, y todo. <Smile type=";)" /><Smile type=":k" /></p>
      <p>Para mí, lo más extraño fue casi no hablar español. Estuve con mi familia todo el tiempo. Por miedo de COVID, no interactuamos con casi nadie. El lugar donde más utilicé mi castellano (y soy más o menos fluente <Smile type=":)" />) fue en el grupo do NaNoWriMo Latinamericano. (Despues de todo, el mes fue noviembre <Smile type=":p" />). Nos encontramos por Zoom – o sea, por el internat, virtualmente <Smile type=":o" /></p>
      <p>La verdad es que yo no experimenté nada de la vida costarricense. ¡Pero yo sí me disfruté muchísimo! <Smile type=":D" /></p>
    </div>,
    ru: <div className="folder-description">
      <p></p>
    </div>,
    pt: <div className="folder-description">
      <p></p>
    </div>,
  },
  photos: [
    {
      url: "iguana",
      title: {
        en: "This\u00A0is Costa\u00A0Rica",
        es: "Mi\u00A0experiencia costarricense fue\u00A0así",
        ru: "Коста-Рика",
        pt: "Isso\u00A0é Costa\u00A0Rica",
      },
      description: {
        en: <p>My family spent the whole month of November in Costa Rica. It was one of the best experiences in my life so far. <ReadMore lang="en">(If you want to know why, read this <a href="/es/Fotografía/iguana">in Spanish</a>.) And yes, we had an iguana who liked to visit our pool <Smile type=":p" /></ReadMore></p>,
        es: <p>Mi familia fue a Costa Rica por el mes entero de noviembre, para huir de los EE UU durante las elecciones. <ReadMore lang="es">¡Y qué maravilloso fue! <Smile type=":D" /> Absolutamente me encantaba despertar, cada dia, al rugido del mar, y ir para nadar, en un cuerpo que finalmente era el mío – y no me daba vergüenza con ninguna parte horrible <Smile type=":K" /> Yo he luchado por una vida entera simplemente para estar aquí… y, finalmente, estoy.</ReadMore></p>,
        ru: <p>Здесь в Коста-Рике, мне нужно было много говорить по-русски <Smile type=";)" /> <ReadMore lang="ru">Здесь я создала большинство своих аккаунтов в криптовалюте. У меня есть русский друг, кто написала мне в Telegram. Она хотела, чтобы я зарегистрировалась на аккаунтах криптовалюта. Когда я не смотрела на игуану у бассейна, Я зарегистрировалась на многих аккаунтах криптовалюта, о которых мой друг узнала <Smile type=":)" /> Сейчас у меня есть много денег инвестирован что заработает больше денег <Smile type="8)" /></ReadMore></p>,
        pt: (funcs) => <p>Na Costa Rica, a única coisa que eu fiz em português foi assistir a uma série brasileira do Netflix, “3%”. <ReadMore lang="pt">(Eu assito isso em português, – claro, com legendas <Smile type=";)" />) Alem disso… a língua aqui é <ChLang lang="es" f={funcs.chlang}>o espanhol</ChLang>! Eu acredito que a iguana não fala português <Smile type="8)" /></ReadMore></p>,
      },
    },
    {
      url: "raccoon",
      title: {
        es: "Otro amigo",
        pt: "Outro amigo",
      },
      description: {
        pt: <p>Este guaxinim não teve medo da gente! <Smile type=":p" /></p>,
      },
    },
    {
      url: "una-familia-y-el-mar",
      title: {
        es: "Una\u00A0familia y\u00A0el\u00A0mar",
        pt: "Enfrentam o mar",
      },
      description: {
        es: <p>No es mi familia. Pero esto es casi el imagen que me habría gustado tener de mi juventud. <Smile type=":/" /> <ReadMore lang="es">Nunca tenía una juventud. Los primeros 29 años de mi vida robados por el «Hombre Infierno» <Smile type=":K" /></ReadMore></p>,
        pt: <p>Apesar de não ser a minha família <Smile type=";)" /></p>,
      },
    },
    {
      url: "el-cielo-ardiente",
      title: {
        en: "The\u00A0sky\u00A0is on\u00A0fire",
        es: "El\u00A0cielo ardiente",
      },
      description: {
        en: <p>Every day we spent in Costa Rica I got to spend the evening with my wonderful family, watching the sun set <Smile type="8)" /></p>,
      },
    },
  ],
};
