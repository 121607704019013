import React from 'react';
import './Smile.css';

const mapEmoticonToName = {
  ":)": "smile.png",
  ":(": "sad.png",
  ";)": "wink.png",
  ":/": "meh.png",
  ":o": "shocked.png",
  ":O": "shocked.png",
  ":0": "shocked.png",
  ":D": "happy.png",
  "8)": "glasses.png",
  ":p": "tongue.png",
  ":P": "tongue.png",
  ":K": "fangs.png",
  ":k": "fangs.png",
  "?": "question.png",
}

function Smile(props) {
  const imgSrc = (props.type) ? mapEmoticonToName[props.type] : "question.png";
  const style = (props.size) ? {width: props.size, height: "auto"} : null;
  return <img
    className="smile"
    src={process.env.PUBLIC_URL + "/images/smilies/" + imgSrc}
    alt={props.type}
    style={style}
  />;
}

export default Smile;
