import React from 'react';
import './Recipe.css';
import Ingredient, {disp_num} from './Ingredient';

function getTotalKcals(ingredients, scale) {
  var min = 0;
  var max = 0;
  for (let i=0;i<ingredients.length;i++) {
    const mult = (ingredients[i].kcal_mult)
      ? ingredients[i].kcal_mult
      : ingredients[i].size;
    if (ingredients[i].kcal !== undefined) {
      min += ingredients[i].kcal*mult;
      max += ingredients[i].kcal*mult;
      continue;
    }
    min += ingredients[i].kcal_min*mult;
    max += ingredients[i].kcal_max*mult;
  }
  min = 5 * Math.round(min*scale/5);
  max = 5 * Math.round(max*scale/5);
  if (min === max) {return min;}
  return min + "-" + max;
}

const ingredients_text = {
  en: "Ingredients",
  es: "Ingredientes",
  ru: "Ингредиенты",
  pt: "Ingredientes",
};

const steps_text = {
  en: "Steps",
  es: "Pasos",
  ru: "Шаги",
  pt: "Passos",
};

const servings_text = {
  en: {
    sing: "serving",
    plu: "servings",
  },
  es: {
    sing: "ración",
    plu: "raciones",
  },
  ru: {
    sing: "порция",
    plu: "порций",
  },
  pt: {
    sing: "porção",
    plu: "porções",
  },
};

const calories_text = {
  en: "calories",
  es: "calorías",
  ru: "калории",
  pt: "calorias",
};

const optional_text = {
  en: "optional",
  es: "opcional",
  ru: "необязательный",
  pt: "opcional",
};

function Recipe(props) {
  const {lang, active, showKcal, setActive, scale, style} = props;
  const recipe = props.data;
  if (!recipe.description[lang]) {return null;}
  var divClass = "recipe";
  if (active) {divClass += " recipe-active";}
  const handleClick = () => setActive();
  var ingredients_list = (active)
    ? recipe.ingredients.map((ingredient) => <Ingredient
      key={ingredient.name[lang]}
      data={ingredient}
      scale={scale}
      showKcal={showKcal}
      lang={lang}
    />) : null;
  var steps_list = (active)
    ? recipe.steps.map((step) => {
      if (!step.directions[lang]) {return null;}
      const optional = (step.optional) ?
        <p className="recipe-step-optional">
          (<i>{optional_text[lang]}</i>)
        </p> : null;
      const style = (step.optional)
        ? {color: "#009020"}
        : null;
      return <li key={step.directions[lang]} style={style}>
        {optional}
        {step.directions[lang](scale)}
        {(step.pointer && step.pointer[lang]) ? step.pointer[lang](scale) : null}
      </li>
    }) : null;
  const details = (active) ? <div className="recipe-details">
    {recipe.description[lang]}
    <div className="recipe-ingredients">
      <h3>{ingredients_text[lang]}:</h3>
      <ul>{ingredients_list}</ul>
    </div>
    <div className="recipe-steps">
      <h3>{steps_text[lang]}:</h3>
      <ol>{steps_list}</ol>
    </div>
  </div> : null;
  const dispServings = disp_num(scale);
  return <div className={divClass} style={style}>
    <div className="recipe-name" onClick={handleClick}>
      <h2>{recipe.name[lang]}</h2>
      <h3 className="recipe-kcals">
        {dispServings + " "} {(scale > 0 && scale <= 1) ? servings_text[lang].sing : servings_text[lang].plu}
        {(showKcal)
          ? " (" + getTotalKcals(recipe.ingredients, scale) + " " + calories_text[lang] + ")"
          : ""}
      </h3>
      <img
        src={"/images/cooking/recipes/" + recipe.image}
        alt=""
      />
    </div>
    {details}
  </div>;
}

export default Recipe;
