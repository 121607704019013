import React, {Component} from 'react';
import './App.css';
import {cap_each} from './funcs';
import Smile from './Smile';
import LangPicker from './LangPicker';
import Window from './Window';
import Option from './Option';
import About, {about_section} from './About';
import Publications from './Publications';
import Photography from './Photography';
import Cooking from './Cooking';
import Blog from './Blog';
// import Crypto from './Crypto';

// eslint-disable-next-line
const coming_soon_text = {
  en: <p>
    This <span id="awesome">AWESOME</span> section is coming soon
    {" "} <Smile type=":D" />
  </p>,
  es: <p>
    ¡Esta sección <span id="awesome">MARAVILLOSA</span> viene pronto!
    {" "} <Smile type=":D" />
  </p>,
  ru: <p>
    Этот <span id="awesome">КЛАССНЫЙ</span> раздел скоро появится
    {" "} <Smile type=":D" />
  </p>,
  pt: <p>
    Esta seção <span id="awesome">INCRÍVEL</span> estará disponível em breve
    {" "} <Smile type=":D" />
  </p>,
  sw: <p>
    Sehemu hii <span id="awesome">NZURI</span> itakuja hivi karibuni!
    {" "} <Smile type=":D" />
  </p>,
  tr: <p>
    Bu <span id="awesome">HARİKA</span> bölüm yakın zamanda gelecek!
    {" "} <Smile type=":D" />
  </p>,
  el: <p>
    Αυτή η <span id="awesome">ΥΠΕΡΟΧΗ</span> ενότητα έρχεται σύντομα!
    {" "} <Smile type=":D" />
  </p>,
  is: <p>..........</p>,

}
// eslint-disable-next-line
const coming_soon = (props) => (<div id="comingsoon">
  {coming_soon_text[props.lang]}
</div>);

const segments = {
  en: {
    pubs: {name: "Publications", comp: Publications},
    photos: {name: "Photography", comp: Photography},
    // cooking: {name: "Cooking", comp: Cooking},
    blog: {name: "Blog", comp: coming_soon},// Blog},
  },
  es: {
    pubs: {name: "Publicaciones", comp: Publications},
    photos: {name: "Fotografía", comp: Photography},
    // cooking: {name: "Cocina", comp: Cooking},
    // blog: {name: "Blog", comp: Blog},
  },
  ru: {
    pubs: {name: "Публикации", comp: Publications},
    photos: {name: "Фотографии", comp: Photography},
    cooking: {name: "Кухня", comp: Cooking},
    // crypto: {name: "Криптовалюты", comp: Crypto},
  },
  pt: {
    pubs: {name: "Publicações", comp: Publications},
    photos: {name: "Fotografia", comp: Photography},
    // cooking: {name: "Cozinha", comp: Cooking},
  },
  sw: {
    pubs: {name: "Hadithi zangu", comp: coming_soon}, // Publications},
    photos: {name: "Picha zangu", comp: Photography},
    // blog: {name: "Blogi yangu", comp: Blog},
  },
  tr: {
    pubs: {name: "Yayınlar", comp: coming_soon},
    photos: {name: "Fotoğraflar", comp: coming_soon},
  },
  el: {
    pubs: {name: "Δημοσιεύσεις", comp: coming_soon},
    photos: {name: "Εικόνες", comp: coming_soon},
    cooking: {name: "Μαγειρική", comp: coming_soon},
    // blog: {name: "Blog", comp: coming_soon},
  },
  is: {
//    photos: {name: "...", comp: coming_soon},
  },
};

var options = {};
for (let i=0;i<Object.keys(segments).length;i++) {
  let lang = Object.keys(segments)[i];
  if (!segments.hasOwnProperty(lang)) {continue;}
  options[lang] = [];
  for (var prop in segments[lang]) {
    if (!segments[lang].hasOwnProperty(prop)) {continue;}
    options[lang].push(prop);
  }
  // Add this now so it's not in the list. 
  segments[lang].about = {name: about_section[lang], comp: About};
}

const name = {
  en: "Jane Struthers",
  es: "Juana Struthers",
  ru: "Иванна Стразерс",
  pt: "Joana Struthers",
  sw: "Yohana Struthers",
  tr: "Cansu Struthers",
  el: "Ιωάννα Στράδερζ",
  is: "Jóna Struðers",
};

const tagline = {
  en: {text: "Author, photographer, programmer, entrepreneur… and all-around wild girl", smile: ":o"},
  es: {text: "Autora, fotógrafa, programista, empresaria… y una chica totalmente loca", smile: ":D"},
  ru: {text: "Автор, фотограф, программист, предприниматель… и настоящая необузданная девушка", smile: ";)"},
  pt: {text: "Autora, fotógrafa, programadora, empresária… e, mais, uma garota totalmente bravia", smile: ":p"},
  sw: {text: "Mwandishi, mpigishi wa picha, mpangishi wa kompyuta, mjasiriamali… na msichana mkali kidogo", smile: "8)"},
  tr: {text: "Yazar, şef (bazen), fotoğrafçı, girişimci… ve tamamen karmaşık kız", smile: ":)"},
  el: {text: "Συγγραφέας, σεφ, φωτογράφός, προγραμματίστρια… και ίσως ένα τρελό κορίτσι", smile: "8)"},
  is: {text: "Stelpa sem talar ekki íslensku", smile: ":o"},
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: "",
      //segment: null,
      path: [],
      lang: "en",
      loadNum: 0,
    };
  }
  changeSelection(name) {
    if (name === this.state.option) {
      this.setUrl("");
      return;
    }
    let url = (name) ? "/" + name : "";
    window.history.pushState(name, "", "/" + this.state.lang + url);
    this.setState({
      option: name,
      path: [name],
    });
  }
  setUrl(url) {
    // If blank, increment load number to force a refresh
    if (!url) {this.setState({loadNum: this.state.loadNum+1});}
    var path = [];
    if (url) {
      path = url.split("/");
      url = "/" + url;
    }
    path.unshift(this.state.option);
    this.setState({path: path});
    window.history.pushState(url, "", "/" + this.state.lang + "/" + this.state.option.replace(/ /g, "-") + url);
  }
  changeLang(lang) {
    const {path} = this.state;
    this.setState({
      lang: lang,
    });
    var url = "/" + lang;
    if (path && path[0]) {url += "/" + path.join("/");}
    window.history.pushState(lang, "", url);
  }
  componentDidMount() {
    const {pathname} = (window) ? window.location : "";
    // It starts with "/" which creates an empty first element. Remove this.
    const path = pathname.split("/").slice(1);
/*    if (path.length && !path[path.length-1]) { // If a trailing slash
      path.pop();
    }*/
    var lang = "en"; // Default to English ;) 
    if (path && path[0]) {
      // Chop the first element off path. It should be "en" or "pt" and determine the language.
      lang = path.shift();
    }
    // If name[lang] isn't defined, use English.
    if (!name[lang]) {lang = "en";}
    var segment = "";
    if (path && path[0]) {
      const choice = path[0];
      if (!segments[lang]) {return;}
      if (segments[lang][choice]) {segment = choice;}
    }
    this.setState({path: path, option: segment, lang: lang});
  }
  render() {
    const {option} = this.state;
    const lang = (this.state.lang && name[this.state.lang])
      ? this.state.lang : "en";
    const cur_tagline = tagline[lang];
    document.title = name[lang];
    document.querySelector('meta[name="description"]')
      .setAttribute("content", cur_tagline.text);
    const menu = (options[lang]) ? options[lang].map(
      (choice) => {
        const extended = (choice === option);
        const section_title = (segments[lang][choice]) ?
          segments[lang][choice].name : "***";
        return <Option
          key={choice}
          name={choice}
          title={section_title}
          w={100/options[lang].length + "%"}
          changeSelection={this.changeSelection.bind(this)}
          extended={extended}
        />;
      }
    ) : [];
    // Default to "About". 
    const segment = option || "about";
    const dispWindow = (segment) ? <Window
      title={segments[lang][segment].name}
      Comp={segments[lang][segment].comp}
      lang={lang}
      chlang={this.changeLang.bind(this)}
      path={this.state.path ? this.state.path.slice(1) : ""}
      setUrl={this.setUrl.bind(this)}
      // Force components to reload occasionaly ;)
      loadNum={this.state.loadNum}
    /> : null;
    const title_style = (segment === "about") ? {
      color: "#FFC8C8",
    } : null;
    var available_langs = null;
    if (segment) {
      available_langs = {};
      for (var l in segments) {
        // If the segment is defined for a given language, pass it into LangPicker as an option.
        if (segments[l][segment]) {
          available_langs[l] = true;
        }
      }
    }
    return (
      <div className="App">
        <header className="App-header">
          <a name="top" />
          <div
            id="banner"
            style={{backgroundImage: "url('/images/banner-" + lang + ".jpg')"}}
            alt="banner"
          >
            <div id="banner_gradient" />
          </div>
          <div id="title_box">
            <div
              id="title"
              style={title_style}
              onClick={() => this.changeSelection("")}
            >
              {name[lang]}
            </div>
            <div id={(lang==="tr")?"tagline-tr":"tagline"}>
              {cur_tagline.text} {" "} <Smile type={cur_tagline.smile} />
            </div>
            <div id="options-menu">
              {menu}
            </div>
          </div>
          <LangPicker
            lang={lang}
            chlang={this.changeLang.bind(this)}
            limit={available_langs}
          />
        </header>
        {dispWindow}
      </div>
    );
  }
}

export default App;
