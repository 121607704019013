import React, {Component} from 'react';
import './Option.css';

class Option extends Component {
  render() {
    const {w, name, title, extended, changeSelection} = this.props;
    return (
      <div
        className="option"
        style={{
          width: w,
          color: (extended) ? "white" : undefined
        }}
      >
        <div className="option-text" onClick={()=>changeSelection(name)}>
          {title}
        </div>
      </div>
    );
  }
}

export default Option;
