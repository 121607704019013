import React from 'react';
import Smile from '../Smile';
import ReadMore from '../ReadMore';
import ChLang from '../ChLang';

// ’ “” – …
// ¿¡ ÁÉÍÓÚ áéíóú ñü – «» …
// ё — «» …
// ÁÉÍÓÚ áéíóú ãõ êô ç Àà – “” …

export const india = {
  url: "india",
  folder: true,
  title: {
    en: "The “Indian Subcontinent”",
    es: "La India",
    ru: "Индия (и рядом)",
  },
  description: {
    en: <div className="folder-description">
      <p>India isn’t the country where I’ve been around the most. (That’s the USA. <Smile type=":o" />) It’s not the country where I’ve spent the most time, or where I’m a citizen. (Again, the USA. <Smile type=":)" />) It’s not my ethnicity. (I’m mixed European, mostly Irish. <Smile type="8)" />) But I have a deep connection to this part of the world.</p>
      <p>India is renowned as a “spiritual” part of the world – and it is, really. My heart belongs here. The story I published <a href="https://www.fiveonthefifth.com/vol-5-issue-7-story-2">of which I’m most proud</a> so far, and which I feel is most authentic to date, is from this region. <Smile type="8)" /> And it’s here where I found God; or maybe God found me. I wasn’t looking. <Smile type=":p" /></p>
      <p>In some ways I feel I was born here. At least, I was reborn here. <Smile type=":p" /> So, yes, I’m an Indian White girl. With a lot of the worst parts of colonialism; I’m sorry. <Smile type=":(" /> There are certain truths about being White in this region that I’d just accepted – and it wasn’t until my family visited me, and was appalled at how I’d ask people for things as if they existed merely to do my bidding, that I became aware how much that isn’t the norm in the world. <Smile type=":o" /> Moreover, despite considering myself a “polyglot”, I’ve never seriously tried to learn Hindi or Urdu. Most people speak English here (a history of British clonialism, right) so I guess I just figured my English was good enough <Smile type=":k" /></p>
      <p>I feel like my life story starts in India. Maybe it’ll end here. Even if not, this part of the world is sure to tie itself into the thread of my life in various ways. Allah, or God, or maybe you want to call it Vishnu or Brahma — whatever you call it, that force will guide me.</p>
        <p>I’m not done here yet. I’ll be back. If for no other reason, because I owe the Indian people that much more than I’ve given them. <Smile type=":/" /></p>
    </div>,
    es: (funcs) => <div className="folder-description">
      <p>Mientras yo estaba en la India, no aprendí casi nada de Hindi ni de Urdu. Todos hablaron <ChLang lang="en" f={funcs.chlang}>inglés</ChLang>, y bastaba saber ese idioma para comunicar. <Smile type=":/" /></p>
    </div>,
    ru: <div className="folder-description">
      <p>Здесь (в Индии) я встретила многих русских друзей. Странно, а? <Smile type=";)" /></p>
    </div>,
  },
  photos: [
    {
      url: "indian-street",
      title: {
        en: "An\u00A0Indian Street",
        es: <span>Una{"\u00A0"}calle{"\u00A0"}de la{"\u00A0"}India{"\u00A0"}<Smile type=":o" /></span>,
        ru: "Улица в\u00A0Индии",
      },
      titleText: {
        es: "Una calle de la India",
      },
    },
    {
      url: "tent-camp-pakistan",
      title: {
        ru: "Другой Пенджаб",
      },
      description: {
        ru: <p>Есть два Пенджаба. В Индии и в Пакистане. Это—Пакистан <Smile type=";)" /></p>
      },
    },
    {
      url: "pakistani-cows",
      title: {
        en: "Pakistani Cows",
      },
      description: {
        en: <p>I wasn’t so tight about being strictly <i>vegan</i> in India – until I learned how Indian cows are <i>really</i> treated. <Smile type=":o" /> <ReadMore lang="en">It’s illegal in most parts of India to kill a cow. Yay, right? Not quite. <Smile type=":/" /> I lated learned what happens to them when their milk-producing days are done. They’re force-marched to places where it <i>is</i> legal to kill them: such as Bangladesh and Pakistan. <Smile type=":(" /> (And many don’t even survive the brutal death march… <Smile type=":k" />)</ReadMore></p>,
      },
    },
    {
      url: "karachi-boats",
      title: {
        en: "Karachi Dock",
      },
    },
    {
      url: "pakistan-restaurant",
      title: {
        en: "A\u00A0Restaurant on\u00A0Lockdown",
//        pt: "O\u00A0coronavírus no\u00A0Paquistão"
      },
      description: {
        en: <p>This was a restaurant we visited in Karachi while the entire city was supposed to be on lockdown because of COVID-19. <ReadMore lang="en">Well, as you can see, the lockdown took a while to happen <Smile type=";)" /> But eventually the police and army moved in to enforce it, and I ended up stuck in Karachi for about a week because EVERYTHING was closed. (Including transportation out of the city!)</ReadMore></p>,
//        pt: <p>Ao contrario do Brasil, o confinemento do corona no Paquistão foi bastante rápido. <ReadMore lang="pt">Todo tardou um pouco em començar, mas quando ele començou, todos os negócios fecharam muito rapidamente <Smile type=":/" /> Eu estava atrapada lá por um tempo, até eu pegar a doença <Smile type=":o" /></ReadMore></p>
      },
    },
  ],
};
